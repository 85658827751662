import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { persistor, store } from "./store/reducer";
import { RootNavigator } from "./navigator/root.navigator";
import { Provider } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./configs";
import { PersistGate } from "redux-persist/integration/react";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
// import { stripePublicKey } from "./components/consts/api-url.const";
// import { PayPalScriptProvider } from "@paypal/react-paypal-js";
// import { paypalClientId } from "./components/consts/api-url.const";
// const stripePromise = loadStripe(stripePublicKey);
// const initialOptions = {
//   clientId: paypalClientId,
//   "disable-funding": "card,credit",
// };
function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* <PayPalScriptProvider options={initialOptions}> */}
            {/* <Elements stripe={stripePromise}> */}
              <Router>
                <CssBaseline />
                <RootNavigator />
              </Router>
            {/* </Elements> */}
          {/* </PayPalScriptProvider> */}
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
