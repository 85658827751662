import { Box, List, ListItemText, Menu, MenuItem } from "@mui/material";
import React from "react";
import { themeColors } from "../../configs";
import { Link as RouterLink } from "react-router-dom";

function DropDownPopup({
  open,
  handleClose,
  playlistItemid,
  anchorEl,
  handleClickEditOpen,
  playlistGenres,
  handleDelete,
  handleClickEditGatesOpen,
  playlistGates,
}: {
  open: boolean;
  handleClose: any;
  playlistItemid: any;
  anchorEl: any;
  handleClickEditOpen: any;
  playlistGenres?: any;
  handleDelete?: any;
  handleClickEditGatesOpen?: any;
  playlistGates?: any;
}) {
  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        "& .MuiMenu-paper.MuiMenu-paper": {
          boxShadow: "4px 4px 4px #0000001A",
          border: "1px solid #00000080",
        },
        "& ul.MuiList-root": {
          padding: "0px !important",
        },
      }}
    >
      <Box
        sx={{
          padding: "0px",
          maxWidth: "620px",
          width: "100%",
        }}
      >
        <Box>
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              bgcolor: "background.paper",
              fontSize: "12px",
              padding: "0px !important",
            }}
          >
            <MenuItem
              sx={{
                "&:hover": {
                  background: themeColors["#00ADB5"],
                  color: "#fff",
                },
                borderBottom: "1px solid #C8C8C8",
                paddingY: "8px ",
              }}
              onClick={() => {
                handleClickEditOpen(playlistItemid, playlistGenres);
                handleClose();
              }}
            >
              <ListItemText
                sx={{
                  "& span.MuiTypography-root": {
                    fontSize: "12px",
                  },
                }}
              >
                Edit Genre
              </ListItemText>
            </MenuItem>

            {/* <MenuItem
              sx={{
                "&:hover": {
                  background: themeColors["#00ADB5"],
                  color: "#fff",
                },
                borderBottom: "1px solid #C8C8C8",
                paddingY: "8px ",
              }}
              onClick={() => {
                handleClickEditGatesOpen(playlistItemid, playlistGates);
                handleClose();
              }}
            >
              <ListItemText
                sx={{
                  "& span.MuiTypography-root": {
                    fontSize: "12px",
                  },
                }}
              >
                Edit Gate
              </ListItemText>
            </MenuItem> */}

            <MenuItem
              sx={{
                "&:hover": {
                  background: themeColors["#00ADB5"],
                  color: "#fff",
                },
                borderBottom: "1px solid #C8C8C8",
                paddingY: "8px ",
              }}
              onClick={() => {
                handleDelete(playlistItemid);
                handleClose();
              }}
            >
              <ListItemText
                sx={{
                  "& span.MuiTypography-root": {
                    fontSize: "12px",
                  },
                }}
              >
                Remove
              </ListItemText>
            </MenuItem>

            <MenuItem
              component={RouterLink}
              to={`/submit-public-page/${playlistItemid}`}
              sx={{
                "&:hover": {
                  background: themeColors["#00ADB5"],
                  color: "#fff",
                },
                borderBottom: "1px solid #C8C8C8",
                paddingY: "8px ",
              }}
            >
              <ListItemText
                sx={{
                  "& span.MuiTypography-root": {
                    fontSize: "12px",
                  },
                }}
              >
                Visit Public Page
              </ListItemText>
            </MenuItem>
          </List>
        </Box>
      </Box>
    </Menu>
  );
}

export default DropDownPopup;
