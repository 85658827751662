import { Box, Link as MuiLink, useMediaQuery } from "@mui/material";
import { themeFonts } from "../../../configs";
// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
import React, { useEffect, useRef, useState } from "react";
// import { ArtistPointOfView } from "./artist-point-of-view";
// import { CuratorsPointOfView } from "./curators-point-of-view-section";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../components/consts/routes.consts";
export const PointOfViewSection = () => {
  // const [value, setValue] = React.useState("artists");
  const isMiniScreen = useMediaQuery("(max-width:500px)");
  // const isMidiScreen = useMediaQuery("(max-width:1150px)");
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [stickyTabs, setStickyTabs] = useState(false);
  const refElement = useRef<any>();
  // const handleChange = (event: React.SyntheticEvent, newValue: string) => {
  //   setValue(newValue);
  // };
  useEffect(() => {
    const handleScroll = () => {
      if (refElement.current) {
        const rect = refElement.current.getBoundingClientRect();
        const isSticky = rect.top <= 70;
        setStickyTabs(isSticky);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "center",
          paddingLeft: "0 !important",
          paddingY: "80px !important",
          background: themeColors["#000000"],
        }}
        ref={refElement}
      >
        <Box>
          <TabContext value={value}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
                position: stickyTabs && isMidiScreen ? "fixed" : "unset",
                top: stickyTabs && isMidiScreen ? "170px" : "unset",
                left: stickyTabs && isMidiScreen ? "50%" : "unset",
                transform:
                  stickyTabs && isMidiScreen
                    ? "translate(-50%, -50%)"
                    : "unset",
                background: stickyTabs && isMidiScreen ? "black" : "unset",
                zIndex: 999,
                borderRadius: "5px",
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                sx={{
                  border: "1px solid #00ADB5",
                  borderRadius: "5px",
                  width: "max-content",
                  minHeight: isMiniScreen ? "45px" : "48px",
                  "& span.MuiTabs-indicator": {
                    display: "none",
                  },
                  " .MuiTabs-flexContainer": {
                    flexDirection: "row",
                  },
                }}
              >
                <Tab
                  label="For Artists"
                  value="artists"
                  sx={{
                    color: "#00ADB5",
                    borderRadius: "5px",
                    minHeight: isMiniScreen ? "45px" : "48px",
                    padding: isMiniScreen ? "0px 0px" : "padding: 12px 16px",
                    width: isMiniScreen ? "180px" : "211px",
                    fontFamily: themeFonts["Poppins-Medium"],
                    fontSize: isMiniScreen ? "12px" : "16px",
                    "&.Mui-selected": {
                      background: "#00ADB5",
                      color: "#FFFFFF",
                    },
                  }}
                />
                <Tab
                  label="For Curators"
                  value="curators"
                  sx={{
                    color: "#00ADB5",
                    borderRadius: "5px",
                    minHeight: isMiniScreen ? "45px" : "48px",
                    padding: isMiniScreen ? "0px 0px" : "padding: 12px 16px",
                    width: isMiniScreen ? "180px" : "211px",
                    fontFamily: themeFonts["Poppins-Medium"],
                    fontSize: isMiniScreen ? "12px" : "16px",
                    "&.Mui-selected": {
                      background: "#00ADB5",
                      color: "#FFFFFF",
                    },
                  }}
                />
              </TabList>
            </Box>

            <TabPanel
              value="artists"
              sx={{ paddingTop: stickyTabs && isMidiScreen ? "71px" : "auto" }}
            >
              <ArtistPointOfView
                isMiniScreen={isMiniScreen}
                stickyTabs={stickyTabs}
                isMidiScreen={isMidiScreen}
              />
            </TabPanel>
            <TabPanel
              value="curators"
              sx={{ paddingTop: stickyTabs && isMidiScreen ? "71px" : "auto" }}
            >
              <CuratorsPointOfView
                isMiniScreen={isMiniScreen}
                stickyTabs={stickyTabs}
                isMidiScreen={isMidiScreen}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Grid> */}
      <Box
        sx={{
          display: "flex",
        }}
      >
        <MuiLink
          component={Link}
          to={ROUTES.SUBMIT_A_SONG}
          sx={{
            color: "#FFFFFF",
            fontSize: isMiniScreen ? "16px" : "24px",
            fontFamily: themeFonts["Poppins-SemiBold"],
            textDecorationColor: "#00ADB5",
            width: "50%",
            backgroundColor: "#0BD67E",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textDecoration: "none",
          }}
        >
          Submit A Song
        </MuiLink>

        <MuiLink
          component={Link}
          to={ROUTES.SUBMIT_A_PLAYLIST}
          sx={{
            color: "#FFFFFF",
            fontSize: isMiniScreen ? "16px" : "24px",
            fontFamily: themeFonts["Poppins-SemiBold"],
            textDecorationColor: "#00ADB5",
            width: "50%",
            backgroundColor: "#00ADB5",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textDecoration: "none",
          }}
        >
          Submit a playlist
        </MuiLink>
      </Box>
    </>
  );
};
