import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import PrivacySectionTableThree from "./privacy-policy-table-3";
import PrivacySectionTableFour from "./privacy-policy-table-4";
import PrivacySectionTableFive from "./privacy-policy-table-5";

export const PrivacyPolicyCcp = ({
  isMediumScreen,
}: {
  isMediumScreen: any;
}) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: "32px",
          border: "0.5px solid #00000040",
          borderRadius: "16px 16px 0px 0px",
          // marginTop: isMediumScreen ? "30px" : "0px",
        }}
      >
        <Box
          sx={{
            paddingRight: "24px",
          }}
        >
          <Typography className="terms-about-text">
            This Privacy Policy for California Residents supplements the
            information contained in Our GENERAL PRIVACY POLICY and applies
            solely to all visitors, users, and others who reside in the State of
            California ("consumers'' or "you"). We adopt this notice to comply
            with the California Consumer Privacy Act of 2018 (CCPA) and any
            terms defined in the CCPA have the same meaning when used in this
            Policy.
          </Typography>
          <Box>
            <Typography className="terms-sub-heading">
              1. Information We Collect
            </Typography>

            <Typography className="terms-about-text">
              We collect information that identifies, relates to, describes
              references, is reasonably capable of being associated with, or
              could reasonably be linked, directly or indirectly, with a
              particular consumer, household, or device ("personal
              information"). Personal information does not include:
            </Typography>

            <Typography className="terms-about-text">
              <ul>
                <li>Publicly available information from government records.</li>
                <li>Deidentified or aggregated consumer information.</li>
                <li>Information excluded from the CCPA's scope, like</li>
                <ul className="policy-list">
                  <li>
                    Health or medical information covered by the Health
                    Insurance Portability and Accountability Act of 1996 (HIPAA)
                    and the California Confidentiality of Medical Information
                    Act (CMIA), clinical trial data, or other qualifying
                    research data;
                  </li>

                  <li>
                    Personal information covered by certain sector-specific
                    privacy laws, including the Fair Credit Reporting Act
                    (FCRA), the Gramm-Leach-Bliley Act (GLBA) or California
                    Financial Information Privacy Act (FIPA), and the Driver's
                    Privacy Protection Act of 1994.
                  </li>
                </ul>
              </ul>
            </Typography>

            <Typography className="terms-about-text">
              In particular, our Website has collected the following categories
              of personal information from consumers within the last twelve (12)
              months: 
            </Typography>
            <PrivacySectionTableThree />
            {/* <table>
              <tr>
                <th>Category</th>
                <th>Examples</th>
                <th>Collected</th>
              </tr>

              <tr>
                <td>A. Identifiers.</td>
                <td>
                  A real name, alias, postal address, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address, account name, Social Security number, driver's
                  license number, passport number, or other similar identifiers.
                </td>
                <td>NO</td>
              </tr>

              <tr>
                <td>
                  B. Personal information categories listed in the California
                  Customer Records statute (Cal. Civ. Code § 1798.80(e)).
                </td>
                <td>
                  A name, signature, Social Security number, physical
                  characteristics or description, address, telephone number,
                  passport number, driver's license or state identification card
                  number, insurance policy number, education, employment,
                  employment history, bank account number, credit card number,
                  debit card number, or any other financial information, medical
                  information, or health insurance information. Some personal
                  information included in this category may overlap with other
                  categories.
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>
                  C. Protected classification characteristics under California
                  or federal law.
                </td>
                <td>
                  Age (40 years or older), race, color, ancestry, national
                  origin, citizenship, religion or creed, marital status,
                  medical condition, physical or mental disability, sex
                  (including gender, gender identity, gender expression,
                  pregnancy or childbirth, and related medical conditions),
                  sexual orientation, veteran or military status, genetic
                  information (including familial genetic information).
                </td>
                <td>NO</td>
              </tr>

              <tr>
                <td>D. Commercial information.</td>
                <td>
                  Records of personal property, products or services purchased,
                  obtained, or considered, or other purchasing or consuming
                  histories or tendencies.
                </td>
                <td>NO</td>
              </tr>

              <tr>
                <td>E. Biometric information</td>
                <td>
                  Genetic, physiological, behavioral, and biological
                  characteristics or activity patterns used to extract a
                  template or other identifier or identifying information, such
                  as fingerprints, faceprints, voiceprints, iris or retina
                  scans, keystroke, gait, or other physical patterns, and sleep,
                  health, or exercise data.
                </td>
                <td>NO</td>
              </tr>

              <tr>
                <td>F. Internet or other similar network activity</td>
                <td>
                  Browsing history, search history, and information on a
                  consumer's interaction with a website, application, or
                  advertisement.
                </td>
                <td>YES</td>
              </tr>

              <tr>
                <td>G. Geolocation data.</td>
                <td>Physical location or movements.</td>
                <td>NO</td>
              </tr>

              <tr>
                <td>H. Sensory data.</td>
                <td>
                  Audio, electronic, visual, thermal, olfactory, or similar
                  information.{" "}
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>I. Professional or employment-related information.</td>
                <td>Current or past job history or performance evaluations.</td>
                <td>NO</td>
              </tr>

              <tr>
                <td>
                  J. Non-public education information (per the Family
                  Educational Rights and Privacy Act (20 U.S.C. Section 1232g,
                  34 C.F.R. Part 99)).
                </td>
                <td>
                  Education records directly related to a student maintained by
                  an educational institution or party acting on its behalf, such
                  as grades, transcripts, class lists, student schedules,
                  student identification codes, student financial information,
                  or student disciplinary records.
                </td>
                <td>NO</td>
              </tr>

              <tr>
                <td>K. Inferences drawn from other personal information.</td>
                <td>
                  Profile reflects a person's preferences, characteristics,
                  psychological trends, predispositions, behavior, attitudes,
                  intelligence, abilities, and aptitudes.
                </td>
                <td>NO</td>
              </tr>
            </table> */}
            <Typography
              className="terms-about-text"
              sx={{ paddingTop: "16px" }}
            >
              We obtain the categories of personal information listed above from
              the following categories of sources:
              <ul>
                <li>
                  Directly from you. For example, when you link your Spotify
                  account with PitchPlaylists or create an account on
                  PitchPlaylists and provide us with your email address and
                  username, or when you submit songs or add playlists on the
                  platform. And when you connect your Spotify account to
                  PitchPlaylists. This includes information such as your
                  username and your Spotify playlists.
                </li>
                <li>
                  Indirectly from you. For example, when other users interact
                  with your playlists or submissions, we may collect information
                  about these interactions to improve our services and provide
                  you with a better experience.
                </li>
              </ul>
            </Typography>
          </Box>
          <Box>
            <Typography className="terms-sub-heading">
              2. Use of Personal Information
            </Typography>

            <Typography className="terms-about-text">
              We may use or disclose the personal information we collect for one
              or more of the following purposes:
              <ul>
                <li>
                  To fulfill or meet the reason you provided the information.
                  For example, if you share your name and contact information to
                  request a price quote or ask a question about our products or
                  services, we will use that personal information to respond to
                  your inquiry.
                  {/* If you provide your personal information to
                  purchase a product or service, we will use that information to
                  process your payment and facilitate delivery. We may also save
                  your information to facilitate new product orders or process
                  returns. */}
                </li>
                <li>
                  To provide, support, personalize, and develop our Website,
                  products, and services.
                </li>
                <li>
                  To create, maintain, customize, and secure your account with
                  us.
                </li>
                {/* <li>
                  To process your requests, purchases, transactions, and
                  payments and prevent transactional fraud.
                </li> */}
                <li>
                  To provide you with support and to respond to your inquiries,
                  including to investigate and address your concerns and monitor
                  and improve our responses.
                </li>
                <li>
                  To personalize your Website experience and to deliver content
                  and product and service offerings relevant to your interests,
                  and via email (with your consent, where required by law).
                </li>
                <li>
                  For testing, research, analysis, and product development,
                  including to develop and improve our Website, products, and
                  services.
                </li>
                <li>
                  To respond to law enforcement requests and as required by
                  applicable law, court order, or governmental regulations.
                </li>
                <li>
                  As described to you when collecting your personal information
                  or as otherwise set forth in the CCPA.
                </li>
                <li>
                  To evaluate or conduct a merger, divestiture, restructuring,
                  reorganization, dissolution, or other sale or transfer of some
                  or all of our assets, whether as a going concern or as part of
                  bankruptcy, liquidation, or similar proceeding, in which
                  personal information held by us about our Website users is
                  among the assets transferred.
                </li>
              </ul>
              <Typography>
                We will not collect additional categories of personal
                information or use the personal information we collected for
                materially different, unrelated, or incompatible purposes
                without providing you notice.
              </Typography>
            </Typography>

            <Typography className="terms-sub-heading">
              3. Sharing Personal Information
            </Typography>
            <Typography className="terms-about-text">
              We may share your personal information by disclosing it to a third
              party for a business purpose. We only make these business purpose
              disclosures under written contracts that describe the purposes,
              require the recipient to keep the personal information
              confidential, and prohibit using the disclosed information for any
              purpose except performing the contract. In the preceding twelve
              (12) months, Company has not disclosed personal information for a
              business purpose to the categories of third parties indicated in
              the chart below. We do not sell personal information, subject to
              your right to opt-out of those sales. Our personal information
              sales do not include information about individuals we know are
              under the age of 18. In the preceding twelve (12) months, Company
              has not sold the following categories of personal information to
              the categories of third parties indicated in the chart below. For
              more on your personal information sale rights, see Personal
              Information Sales Opt-Out and Opt-In Rights.
            </Typography>

            <Typography className="terms-about-text">
              We do not sell personal information, subject to your right to
              opt-out of those sales. Our personal information sales do not
              include information about individuals we know are under the age of
              18. In the preceding twelve (12) months, Company has not sold the
              following categories of personal information to the categories of
              third parties indicated in the chart below. For more on your
              personal information sale rights, see Personal Information Sales
              Opt-Out and Opt-In Rights.
            </Typography>

            <PrivacySectionTableFive />

            <Typography className="terms-sub-heading">
              4. Your Rights and Choices
            </Typography>
            <Typography className="terms-about-text">
              The CCPA provides consumers (California residents) with specific
              rights regarding their personal information. This section
              describes your CCPA rights and explains how to exercise those
              rights.
            </Typography>

            <Typography className="terms-about-text-italic">
              Right to Know and Data Portability
            </Typography>
            <Typography className="terms-about-text">
              You have the right to request that we disclose certain information
              to you about our collection and use of your personal information
              over the past 12 months (the "right to know"). Once we receive
              your request and confirm your identity (see Exercising Your Rights
              to Know or Delete), we will disclose to you:
              <ul>
                <li>
                  The categories of personal information we collected about you.
                </li>
                <li>
                  The categories of sources for the personal information we
                  collected about you.
                </li>
                <li>
                  Our business or commercial purpose for collecting or selling
                  that personal information.
                </li>

                <li>
                  The categories of third parties with whom we share that
                  personal information.
                </li>

                <li>
                  If we sold or disclosed your personal information for a
                  business purpose, two separate lists disclosing:
                  <ul className="policy-list">
                    <li>
                      sales, identifying the personal information categories
                      that each category of recipient purchased; and
                    </li>
                    <li>
                      disclosures for a business purpose, identifying the
                      personal information categories that each category of
                      recipient obtained.
                    </li>
                  </ul>
                </li>
                <li>
                  The specific pieces of personal information we collected about
                  you (also called a data portability request).
                </li>
              </ul>
            </Typography>

            <Typography className="terms-about-text-italic">
              Right to Delete
            </Typography>
            <Typography className="terms-about-text">
              You have the right to request that we delete any of your personal
              information that we collected from you and retained, subject to
              certain exceptions (the "right to delete"). Once we receive your
              request and confirm your identity (see Exercising Your Rights to
              Know or Delete), we will review your request to see if an
              exception allowing us to retain the information applies. We may
              deny your deletion request if retaining the information is
              necessary for us or our service provider(s) to:{" "}
              <ul>
                <li>
                  Complete the transaction for which we collected the personal
                  information, provide a good or service that you requested,
                  take actions reasonably anticipated within the context of our
                  ongoing business relationship with you, fulfill the terms of a
                  written warranty or product recall conducted in accordance
                  with federal law, or otherwise perform our contract with you.{" "}
                </li>
                <li>
                  Detect security incidents, protect against malicious,
                  deceptive, fraudulent, or illegal activity, or prosecute those
                  responsible for such activities. Complete the transaction for
                  which we collected the personal information, provide a good or
                  service that you requested, take actions reasonably
                  anticipated within the context of our ongoing business
                  relationship with you, fulfill the terms of a written warranty
                  or product{" "}
                </li>
                <li>
                  Debug products to identify and repair errors that impair
                  existing intended functionality.
                </li>
                <li>
                  Exercise free speech, ensure the right of another consumer to
                  exercise their free speech rights or exercise another right
                  provided for by law.
                </li>
                <li>
                  Comply with the California Electronic Communications Privacy
                  Act (Cal. Penal Code § 1546 et. seq.).
                </li>
                <li>
                  Engage in public or peer-reviewed scientific, historical, or
                  statistical research in the public interest that adheres to
                  all other applicable ethics and privacy laws, when the
                  information's deletion may likely render impossible or
                  seriously impair the research's achievement if you previously
                  provided informed consent.
                </li>
                <li>
                  Enable solely internal uses that are reasonably aligned with
                  consumer expectations based on your relationship with us.
                </li>
                <li>
                  Enable solely internal uses that are reasonably aligned with
                  consumer expectations based on your relationship with us.
                </li>
                <li>Comply with a legal obligation.</li>
                <li>
                  Make other internal and lawful uses of that information that
                  are compatible with the context in which you provided it.
                </li>
              </ul>
              <Typography className="terms-about-text">
                We will delete or de-identify personal information not subject
                to one of these exceptions from our records and will direct our
                service providers to take similar action.
              </Typography>
            </Typography>

            <Typography className="terms-about-text-italic">
              Exercising Your Rights to Know or Delete
            </Typography>
            <Typography className="terms-about-text">
              To exercise your rights to know or delete described above, please
              submit a request by either:
              <ul>
                <li>
                  Visiting{" "}
                  <Link
                    // to={"https://pitch-playlist.vercel.app/"}
                    to={"https://pitchplaylists.com/"}
                    target="_blank"
                    className="terms-link-text"
                  >
                    https://pitchplaylists.com/
                  </Link>{" "}
                  <br />
                  Once on the website, log in to your Pitchplaylists account,
                  navigate to the 'Account Settings' section, and select the
                  option to delete your account and all associated data. Follow
                  the prompts to confirm your decision. Due to full integration
                  with the Spotify API, certain actions, such as managing your
                  account, must be done through Spotify settings. If applicable,
                  manage and remove data linked with your Spotify account
                  through Spotify settings. For any inquiries or requests
                  regarding your data, including data removal, please refer to
                  our Privacy Policy or Email us at{" "}
                  <Link
                    to="mailto:info@pitchplaylists.com"
                    className="text-color-primary"
                  >
                    info@pitchplaylists.com
                  </Link>{" "}
                </li>

                <li>
                  Visiting{" "}
                  <Link
                    // to={"https://pitch-playlist.vercel.app/"}
                    to={"https://pitchplaylists.com/"}
                    target="_blank"
                    className="terms-link-text"
                  >
                    https://pitchplaylists.com/
                  </Link>{" "}
                  <Typography className="terms-about-text">
                    Only you, or someone legally authorized to act on your
                    behalf, may make a request to know or delete related to your
                    personal information.
                  </Typography>
                  <Typography className="terms-about-text">
                    You may only submit a request to know twice within a
                    12-month period. Your request to know or delete must:
                  </Typography>
                </li>

                <li>
                  Provide sufficient information that allows us to reasonably
                  verify you are the person about whom we collected personal
                  information or an authorized representative, which may
                  include:
                  <ul className="policy-list">
                    <li>
                      We may request specific identification information to
                      verify your identity, such as your name, address, email,
                      or phone number, which must match the information we have
                      on record.
                    </li>
                    <li>
                      you may be required to complete an authentication process,
                      which could include providing a password, answering
                      security questions, or confirming a verification code sent
                      to your registered email address or phone number.
                    </li>
                  </ul>
                </li>

                <li>
                  Describe your request with sufficient detail that allows us to
                  properly understand, evaluate, and respond to it
                </li>
              </ul>
              <Typography className="terms-about-text">
                We cannot respond to your request or provide you with personal
                information if we cannot verify your identity or authority to
                make the request and confirm the personal information relates to
                you.
              </Typography>
              <Typography className="terms-about-text">
                You do not need to create an account with us to submit a request
                to know or delete. However, we do consider requests made through
                your password protected account sufficiently verified when the
                request relates to personal information associated with that
                specific account.
              </Typography>
              <Typography className="terms-about-text">
                We will only use personal information provided in the request to
                verify the requestor's identity or authority to make it.
              </Typography>
            </Typography>
            <Typography className="terms-about-text-italic">
              Response Timing and Format
            </Typography>

            <Typography className="terms-about-text">
              We will confirm receipt of your request within ten (10) business
              days. If you do not receive confirmation within the 10-day
              timeframe, please contact{" "}
              <Link
                to="mailto:info@pitchplaylists.com"
                className="text-color-primary"
              >
                info@pitchplaylists.com
              </Link>{" "}
            </Typography>
            <Typography className="terms-about-text">
              We endeavor to substantively respond to a verifiable consumer
              request within forty-five (45) days of its receipt. If we require
              more time (up to another 45 days), we will inform you of the
              reason and extension period in writing.
            </Typography>
            <Typography className="terms-about-text">
              If you have an account with us, we will deliver our written
              response to that account. If you do not have an account with us,
              we will deliver our written response by mail or electronically, at
              your option.
            </Typography>
            <Typography className="terms-about-text">
              Any disclosures we provide will only cover the 12-month period
              preceding our receipt of your request. The response we provide
              will also explain the reasons we cannot comply with a request, if
              applicable. For data portability requests, we will select a format
              to provide your personal information that is readily usable and
              should allow you to transmit the information from one entity to
              another entity without hindrance[, specifically in a common
              machine-readable format (e.g. CSV).
            </Typography>
            <Typography className="terms-about-text">
              We do not charge a fee to process or respond to your verifiable
              consumer request unless it is excessive, repetitive, or manifestly
              unfounded. If we determine that the request warrants a fee, we
              will tell you why we made that decision and provide you with a
              cost estimate before completing your request.
            </Typography>

            <Typography className="terms-sub-heading">
              {" "}
              5. Non-Discrimination
            </Typography>

            <Typography className="terms-about-text">
              {" "}
              We will not discriminate against you for exercising any of your
              CCPA rights. Unless permitted by the CCPA, we will not:
              <ul>
                <li>Deny you goods or services.</li>
                <li>
                  Charge you different prices or rates for goods or services,
                  including through granting discounts or other benefits, or
                  imposing penalties.
                </li>
                <li>
                  Provide you with a different level or quality of goods or
                  services.
                </li>
              </ul>
            </Typography>

            <Typography className="terms-about-text">
              However, we may offer you certain financial incentives permitted
              by the CCPA that can result in different prices, rates, or quality
              levels. Any CCPA-permitted financial incentive we offer will
              reasonably relate to your personal information's value and contain
              written terms that describe the program's material aspects.
              Participation in a financial incentive program requires your prior
              opt-in consent, which you may revoke at any time.{" "}
            </Typography>

            <Typography className="terms-sub-heading">
              {" "}
              6. CCPA Rights Request Metrics
            </Typography>
            <Typography className="terms-about-text">
              Metrics regarding the consumer rights requests we received from
              California residents from January 1,2022 to December 31, 2023
              appear in the following chart:
            </Typography>

            <PrivacySectionTableFour />
            {/* 
            <table>
              <tr>
                <th>Request Type</th>
                <th>Received</th>
                <th>Granted (in whole or in part)</th>
                <th>Denied</th>
                <th> Days to Respond</th>
              </tr>
              <tr>
                <td>Requests to Know</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
              </tr>

              <tr>
                <td>Requests to Delete</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
              </tr>
              <tr>
                <td>Requests to Opt-Out of Personal Information Sales</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
                <td>0</td>
              </tr>

            </table> */}

            <Typography className="terms-sub-title" sx={{ marginTop: "15px" }}>
              Other California Privacy Rights
            </Typography>
            <Typography className="terms-about-text">
              California's "Shine the Light" law (Civil Code Section § 1798.83)
              permits users of our Website who are California residents to
              request certain information regarding our disclosure of personal
              information to third parties for their direct marketing purposes.
              To make such a request, please send an email to Link{" "}
              <Link
                to="mailto:info@pitchplaylists.com"
                className="text-color-primary"
              >
                info@pitchplaylists.com
              </Link>{" "}
            </Typography>

            <Typography className="terms-sub-heading">
              7. Changes to Our Privacy Policy
            </Typography>

            <Typography>
              We reserve the right to amend this privacy policy at our
              discretion and at any time. When we make changes to this privacy
              policy, we will post the updated notice on the Website and update
              the notice's effective date. Your continued use of our Website
              following the posting of changes constitutes your acceptance of
              such changes.
            </Typography>
            <Typography className="terms-sub-heading">
              8. Contact Information
            </Typography>
            <Typography>
              If you have any questions or comments about this notice, the ways
              in which We collects and uses your information described here and
              in the Privacy Policy, your choices and rights regarding such use,
              or wish to exercise your rights under California law, please do
              not hesitate to contact us at:
            </Typography>

            <Typography
              sx={{
                marginLeft: "50px",
                marginY: "5px",
              }}
            >
              Website:{" "}
              <Link
                // to={"https://pitch-playlist.vercel.app/"}
                to={"https://pitchplaylists.com/"}
                target="_blank"
                className="terms-link-text"
              >
                https://pitchplaylists.com/
              </Link>{" "}
            </Typography>

            <Typography
              sx={{
                marginLeft: "50px",
                marginY: "5px",
              }}
            >
              Email:{" "}
              <Link
                to="mailto:info@pitchplaylists.com"
                className="text-color-primary"
              >
                info@pitchplaylists.com
              </Link>{" "}
            </Typography>

            <Typography>
              If you need to access this Policy in an alternative format due to
              having a disability, please contact{" "}
              <Link
                to="mailto:info@pitchplaylists.com"
                className="text-color-primary"
              >
                info@pitchplaylists.com
              </Link>{" "}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
