/* eslint-disable @typescript-eslint/no-unused-vars */ import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LogBackground4 } from "../../pngs";
import { SearchComponents } from "../../components/filter/search-component";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useGetCuratorsListQuery } from "../../components/apis/curatorsApi";
import { ROUTES } from "../../components/consts/routes.consts";
import { useNavigate } from "react-router-dom";
import { GreenDot } from "../../svgs";
import { themeColors, themeFonts } from "../../configs";

export const SubmitPlaylistPage = () => {
  const isMiniScreen = useMediaQuery("(max-width:500px)");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const playlist = "https://open.spotify.com/playlist";
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState<string>("");
  const parts = searchInput.split("/");
  const lastPart = parts[parts.length - 1];
  const [playlistId, queryString] = lastPart.split("?");
  const { refetch } = useGetCuratorsListQuery<any>(
    { playlistId: playlistId },
    {
      skip: !searchInput.includes(playlist),
    }
  );
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const isLoggedIn = token && userId;
  const handleConnect = async () => {
    // window.location.href = `http://localhost:9090/api/auth/login?playlistId=${playlistId}`;
    window.location.href = `https://api.pitchplaylists.com/api/auth/login?playlistId=${playlistId}`;
  };
  const handleSearchChange = (value: React.SetStateAction<string>) => {
    setSearchInput(value);
  };
  useEffect(() => {
    if (searchInput.includes(playlist)) {
      if (isLoggedIn) {
        refetch({ playlistId: playlistId }).then((response: { data: any }) => {
          navigate(ROUTES.CONFIRM_PLAYLIST, {
            state: { playlistData: response.data },
          });
          if (response) {
            window.localStorage.setItem("playlist", "playlist");
          }
        });
      } else {
        handleConnect();
      }
    } else {
      setTimeout(() => {
        setSearchInput("");
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, searchInput]);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const isInvalid = searchInput !== "" && !searchInput.includes(playlist);
  return (
    <Grid
      sx={{
        background: `url(${LogBackground4})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",
        marginTop: "-80px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            maxWidth: "478px",
            width: "100%",
            textAlign: "center",
            padding: "0 15px",
          }}
        >
          <Typography
            sx={{
              fontSize: isMiniScreen ? "28px" : "36px",
              fontWeight: "600",
              color: "#ffffff",
              marginBottom: "20px",
            }}
          >
            Add Your Playlist
          </Typography>
          <SearchComponents
            searchTitle={"Insert your Spotify playlist link"}
            isEmpty={true}
            onSearchChange={handleSearchChange}
            isInvalid={isInvalid}
            value={searchInput}
          />
          <Button
            className="need-help-btn"
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              color: "#fff",
              marginTop: "16px",
              textAlign: "end  !important",
              cursor: "pointer",
              background: "transparent",
              padding: "0px",
              height: "auto",
              display: "flex",
              marginLeft: "auto",
            }}
          >
            Need Help?
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box
              sx={{
                padding: "12px",
                maxWidth: "620px",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "38px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#000000",
                  }}
                >
                  Using Spotify Playlist Links
                </Typography>
                <Box
                  sx={{
                    marginLeft: "auto",
                  }}
                >
                  <CloseIcon
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={handleClose}
                  ></CloseIcon>
                </Box>
              </Box>
              <Box>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                    paddingTop: "5px !important",
                  }}
                >
                  <ListItem
                    sx={{
                      padding: "0px !important",
                    }}
                  >
                    <ListItemText
                      sx={{
                        "& span.MuiListItemText-primary": {
                          display: "flex",
                          fontSize: "12px",
                          color: themeColors["#00000080"],
                          fontFamily: themeFonts["Poppins-Regular"],
                          alignItems: "center",
                        },
                      }}
                      primary={` 1.  Go to your playlist on Spotify`}
                    />
                  </ListItem>

                  <ListItem
                    sx={{
                      padding: "0px !important",
                    }}
                  >
                    <ListItemText
                      sx={{
                        "& span.MuiListItemText-primary": {
                          display: "flex",
                          fontSize: "12px",
                          color: themeColors["#00000080"],
                          fontFamily: themeFonts["Poppins-Regular"],
                          alignItems: "center",
                          gap: "5px",
                        },
                      }}
                    >
                      2. Click the{" "}
                      <GreenDot
                        style={{
                          boxSizing: "content-box",
                          cursor: "pointer",
                        }}
                      />
                      icon
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    sx={{
                      padding: "0px !important",
                    }}
                  >
                    <ListItemText
                      sx={{
                        "& span.MuiListItemText-primary": {
                          display: "flex",
                          fontSize: "12px",
                          color: themeColors["#00000080"],
                          fontFamily: themeFonts["Poppins-Regular"],
                          alignItems: "center",
                          gap: "5px",
                        },
                      }}
                    >
                      3. Select{" "}
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: themeColors["#00ADB5"],
                          fontFamily: themeFonts["Poppins-Regular"],
                          cursor: "pointer",
                        }}
                      >
                        Share
                      </Typography>{" "}
                      , then{" "}
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: themeColors["#00ADB5"],
                          fontFamily: themeFonts["Poppins-Regular"],
                          cursor: "pointer",
                        }}
                      >
                        copy link to playlist
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    sx={{
                      padding: "0px !important",
                    }}
                  >
                    <ListItemText
                      sx={{
                        "& span.MuiListItemText-primary": {
                          display: "flex",
                          fontSize: "12px",
                          color: themeColors["#00000080"],
                          fontFamily: themeFonts["Poppins-Regular"],
                          alignItems: "center",
                        },
                      }}
                      primary={` 4. Paste in your link to our submission bar`}
                    />
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Popover>
        </Box>
      </Box>
    </Grid>
  );
};
