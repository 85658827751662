import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export const CookiesPolicy = ({ isMediumScreen }: { isMediumScreen: any }) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: "32px",
          border: "0.5px solid #00000040",
          borderRadius: "16px 16px 0px 0px",
        }}
      >
        <Box
          sx={{
            paddingRight: "24px",
          }}
        >
          <Typography className="terms-about-text">
            This Cookie Policy applies only to this website and describes how
            and why we use cookies. We gather data that can assist us in
            enhancing our website. We seek your authorization to collect this
            data upon your entry into the site. All automatically gathered
            information, subject to your consent, is compiled in an aggregated
            format and does not allow for the identification of you as a
            specific person.
          </Typography>
          <Box>
            <Typography className="terms-sub-heading">
              1. What are cookies?
            </Typography>
            <Typography className="terms-about-text">
              A cookie is a piece of text stored by a website's server in your
              computer's browser or on your mobile device when you access the
              website.
            </Typography>
            <Typography className="terms-about-text">
              This cookie contains a distinctive code that permits Us to
              recognize your browser while you're navigating our website
              (referred to as a "session" cookie) or during subsequent visits
              (known as a "persistent" cookie).
            </Typography>
            <Typography className="terms-about-text">
              Each cookie is unique to your web browser. Cookies may be set by
              the visited website's server or by partners collaborating with the
              website, referred to as "third-party cookies."
            </Typography>
            <Typography className="terms-about-text">
              Cookies generally enhance user interaction with the website,
              making it more convenient and swift, aiding users in navigating
              various sections of the website. Additionally, cookies may be
              utilized to tailor the website's content to the visitor, aligning
              it with their personal preferences and requirements.
            </Typography>
          </Box>
          <Box>
            <Typography className="terms-sub-heading">
              2. Usage of Cookies
            </Typography>
            <Typography className="terms-about-text">
              The operator of{" "}
              <Link
                to="mailto:info@pitchplaylists.com"
                className="text-color-primary"
              >
                info@pitchplaylists.com
              </Link>{" "}
              is:
              <Typography>
                MUZQ SIA Company.
                <br />
                Riga, Latvia
              </Typography>
            </Typography>

            <Typography className="terms-about-text">
              We utilize cookies to optimize the functionality of the website by
              saving your preferences. This includes essential cookies,
              functional/preference cookies.
            </Typography>
            <Typography className="terms-about-text">
              Consent from the user is necessary for utilizing statistical,
              functional, and marketing cookies on osassistance.com. The user
              retains the right to withdraw their consent to cookie usage at any
              time. Refer to section 5 of this Cookie Policy for guidance on
              cookie management and deletion. See the full list of cookies on
              {""}{" "}
              <Link to={""} target="_blank" className="terms-link-text">
                Cookie List
              </Link>
            </Typography>

            <Typography className="terms-about-text">
              <span className="terms-sub-title">Essential Cookies: </span>
              These cookies are vital for the website's operation and cannot be
              deactivated on this website. They are typically activated in
              response to your specific requests, such as configuring your
              privacy preferences, signing in, or completing forms. These
              cookies do not retain any personally identifiable information.
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">Functional Cookies: </span>
              Functional cookies enable the website to provide enhanced features
              and tailored experiences. These cookies may be set by us or by
              third-party providers whose tools or services are incorporated
              into our pages. If you choose to disable these cookies, some or
              all of these features may not function as effectively.
            </Typography>

            {/* <Typography className="terms-about-text">
              <span className="terms-sub-title">
                Analytical/Statistical Cookies:{" "}
              </span>
              Analytical cookies are employed to gather data about the website's
              usage, aiming to enhance the content, tailor it to visitor
              preferences, and enhance user experience.
            </Typography>

            <Typography className="terms-about-text">
              <span className="terms-sub-title">Data Collected: </span>
              <ul>
                <li>
                  Cookie consent (permission to place cookies on your device)
                </li>
                <li>Visitor ID (assigned by a cookie placed on your device)</li>
                <li>
                  IP address (internet device address used for statistical
                  purposes only)
                </li>
                <li>Referrer (source of visit, e.g., google.com)</li>
                <li>
                  Location, date, and time of visit (e.g., United States,
                  xx/xx/xxxx, 12.00 PM)
                </li>
                <li>
                  Details about the device used for the visit (e.g., operating
                  system, browser, etc.)
                </li>
                <li>
                  File downloads and content shared on social media or other
                  available platforms (e.g., share buttons)
                </li>
              </ul>
            </Typography> */}
            {/* <Typography className="terms-about-text">
              <span className="terms-sub-title">Marketing Cookies: </span>
              Marketing cookies are employed for analytics and research, and to
              present personalized and relevant content, ascertain the frequency
              of specific ad displays, assess the effectiveness of advertising
              campaigns, track data from prior visits, and share aggregated data
              with involved third parties like advertisers. These cookies are
              deployed on the website and stored on your device by third
              parties.
            </Typography> */}

            <Typography className="terms-sub-heading">
              3. Third-Party Cookies
            </Typography>
            <Typography className="terms-about-text">
              We have the option to utilize third-party cookies, which are
              cookies placed into the user's browser by an external entity not
              affiliated with Us.
            </Typography>
            <Typography className="terms-about-text">
              The data gathered from these third-party cookies remains beyond
              Our reach. Instead, it is exclusively collected and utilized by
              the respective third party for their particular objectives. We may
              incorporate images and video content from platforms such as
              YouTube. Consequently, when you visit Our site featuring embedded
              content from external sources, you may come across cookies from
              these platforms. The control over the dissemination of these
              cookies is beyond Our control. For comprehensive insights into
              their cookie policies, please consult the respective third-party
              website.
            </Typography>

            <Typography className="terms-about-text">
              We incorporate Spotify into our website to streamline content
              management for users on this platform. If you are authenticated
              with their services, Spotify may place a cookie. We do not have
              control over how these cookies are set or used. For detailed
              information about their cookie practices, we recommend visiting
              the respective third-party website.
            </Typography>

            <Typography className="terms-sub-heading">
              4. Cookies Utilized on Our Websites
            </Typography>
            <Typography className="terms-about-text">
              We implement cookies, the specifics of which are outlined in depth
              through the cookie List available on website
            </Typography>
            <Typography className="terms-about-text">
              Within the cookie inventory, you can access comprehensive details
              regarding the entity placing the cookie, the cookie's
              nomenclature, and the classification of the cookie (1st, 2nd, or
              3rd party cookie).
            </Typography>
            <Typography className="terms-sub-heading">
              5. Managing Cookies
            </Typography>
            <Typography className="terms-about-text">
              You are required to express your preference regarding permitting
              or disabling the placement of cookies on your device.
            </Typography>

            <Typography className="terms-about-text">
              Declining specific cookie usage may result in limited
              functionality on the site, potentially hindering access to certain
              services offered on our websites.
            </Typography>
            <Typography className="terms-about-text">
              Should you wish to regulate or inhibit cookie usage, kindly
              communicate your preferences through the cookie exhibited on the
              website. Alternatively, you can manage cookie usage via your
              browser settings, a process elucidated in your browser's Help
              function.
            </Typography>
            <Typography className="terms-about-text">
              Please bear in mind that restricting cookie usage might impact the
              functionality.
            </Typography>

            <Typography className="terms-sub-heading">
              {" "}
              6. Data Privacy
            </Typography>
            <Typography className="terms-about-text">
              When you consent to analytics cookies, we gather data such as
              general information about your computer and its location, along
              with the website of origin.
            </Typography>
            <Typography className="terms-about-text">
              This data does not enable individual identification and is
              strictly utilized in an aggregated format. It helps us understand
              the origin of our visitors, their interactions, and where they
              spend the most time.{" "}
            </Typography>
            <Typography className="terms-about-text">
              Any time we request personally identifiable information that could
              identify you individually, we seek your consent. We're committed
              to providing a clear explanation regarding the purpose, method,
              storage, and usage of the information we collect.
            </Typography>
            <Typography className="terms-about-text">
              We solely collect and retain personally identifiable information
              for the explicitly stated purpose during the collection process.
              After processing your information for the stated purpose(s), we
              ensure your privacy by promptly erasing and destroying the data.
            </Typography>
            <Typography className="terms-about-text">
              Certain data is acquired through cookies, aligning with our
              legitimate interest. This helps improve our website, enhance user
              experiences, gather statistics, optimize functionality, and
              facilitate effective marketing and retargeting.
            </Typography>
            <Typography className="terms-about-text">
              For each cookie type gathering personal data for specific
              purposes, We process the collected personal data exclusively for
              the purposes for which you've consented.
            </Typography>
            <Typography className="terms-about-text">
              Rest assured, all your personal data remains confidential and is
              used strictly in accordance with the aforementioned purposes.
            </Typography>
            <Typography className="terms-about-text">
              We may collaborate with third-party service providers who access
              and process your personal data. Additionally, we may transfer
              personal data to other Our group entities. If personal data is
              processed by a third party outside the EU/EEA, this is executed
              under the EU Commission’s Standard Contractual Clauses. Transfers
              to other Our group entities outside the EU/EEA adhere to Binding
              Corporate Rules.
            </Typography>

            <Typography className="terms-about-text">
              Under data protection regulations, you possess specific rights,
              including:
              <ul>
                <li>Access to an overview of your personal data held by us</li>
                <li>
                  Receipt of your personal data in a structured, commonly used,
                  and machine-readable format
                </li>
                <li>Update or correction of your personal data</li>
                <li>Deletion or destruction of your personal data</li>
                <li>
                  Restriction or cessation of the processing of your personal
                  data
                </li>
                <li>
                  Withdrawal of consent for us to process your personal data,
                  without affecting prior lawful processing
                </li>
                <li>
                  Filing a complaint about our personal data processing with a
                  Data Protection Authority
                </li>
              </ul>
              <Typography className="terms-about-text">
                Applicable law might impose limitations on these rights based on
                specific processing circumstances. For inquiries or requests
                concerning these rights, please reach out to Us at:{" "}
                <Link
                  to="mailto:info@pitchplaylists.com"
                  className="text-color-primary"
                >
                  info@pitchplaylists.com
                </Link>{" "}
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
