import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export const TermsOfServices = ({
  isMediumScreen,
}: {
  isMediumScreen: any;
}) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: "32px",
          border: "0.5px solid #00000040",
          borderRadius: "16px 16px 0px 0px",
        }}
      >
        <Box
          sx={{
            paddingRight: "24px",
          }}
        >
          <Typography className="terms-about-text">
            Welcome, and thank you for your interest in PitchPlaylists, Website
            ("We, Our, Us, Service Provider, PitchPlaylists"), which operates
            the website located at{" "}
            <Link
              // to={"https://pitch-playlist.vercel.app/"}
              to={"https://pitchplaylists.com/"}
              target="_blank"
              className="terms-link-text"
            >
              www.pitchplaylists.com
            </Link>{" "}
            and other websites (collectively, the “Website”) and related online
            services, including, but not limited to, any Downloadable Content
            (as defined below), and any other products and services that We may
            provide now or in the future (collectively, the “Services”
            “Products”). The following Terms of Service are a legal contract
            between you (“you” and “your”) and PitchPlaylists regarding your use
            of the Services. Visitors and users of the Services are referred to
            individually as “User” and collectively as “Users”. Use of the
            Services is governed by these Terms of Service and our Privacy
            Policy.
          </Typography>
          <Typography className="terms-about-text">
            PLEASE READ THE FOLLOWING TERMS OF SERVICE CAREFULLY. BY REGISTERING
            FOR, ACCESSING, BROWSING, OR USING THE WEBSITE, YOU ACKNOWLEDGE THAT
            YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THE FOLLOWING
            TERMS AND CONDITIONS, AND ANY ADDITIONAL GUIDELINES (AS DEFINED
            BELOW) (COLLECTIVELY, THE “TERMS”), AND THAT YOU HAVE READ OUR
            PRIVACY POLICY AND (AS APPLICABLE TO WHICH SERVICES YOU CHOOSE TO
            USE).
          </Typography>
          <Typography className="terms-about-text">
            We are a playlist management platform designed to help playlist
            curators efficiently manage their playlists and discover new music
            submitted by other users on our platform. This platform provides a
            space for artists to submit their music directly to curators,
            enabling curators to respond with a message to either "accept" or
            "reject" the submission. Our tools aim to streamline the process of
            connecting artists and curators, fostering collaboration and music
            discovery.
          </Typography>
          <Box>
            <Typography className="terms-sub-heading">
              1. Access and Use of the Service
            </Typography>
            <Typography className="terms-sub-title">
              Your Registration Obligations
            </Typography>
            <Typography className="terms-about-text">
              To use our services, users need to log in with their Spotify
              account. If you already have a Spotify account, simply use it to
              log in. If you don't have a Spotify account, you will need to
              create one first and then use it to log in to our service and
              create a PitchPlaylists account.
            </Typography>
            <Typography className="terms-about-text">
              Here's the process:
            </Typography>
            <Typography className="terms-about-text">
              1. Create a Spotify Account: If you don’t have a Spotify account,
              register with Spotify to create one (“Spotify Account”).
            </Typography>
            <Typography className="terms-about-text">
              2. Log in with Spotify: Use your Spotify account to log in to our
              service and create your PitchPlaylists account (“Account”).
            </Typography>
            <Typography className="terms-about-text">
              During this process, we will access certain information from your
              Spotify Account, such as your name, location, and other details
              allowed by your Spotify privacy settings. This information is
              necessary for us to provide you with the full range of our
              features.
            </Typography>
            <Typography className="terms-sub-title">Content</Typography>
            <Typography className="terms-about-text">
              Service Provider Content: While using Our services, you will have
              access to and the ability to access or download specific content
              provided by PitchPlaylists ("Service Provider Content") or content
              supplied by third parties ("Third-Party Content"). Each piece of
              content is subject to the license associated with it. By using or
              downloading any content, you agree to abide by the relevant
              license terms. Documentation, resources, or summaries created by
              PitchPlaylists are the exclusive intellectual property of
              PitchPlaylists and may not be shared without explicit permission.
              It is your responsibility to ensure that you possess the
              appropriate permissions for the usage of any content. If you are
              uncertain about the applicable license for specific content, it is
              advisable to seek clarification from the content's author (i.e.,
              contact the individual who owns usage rights and obtain permission
              if necessary).
            </Typography>
            <Typography className="terms-about-text">
              User Content: While using Service, users may upload various types
              of content, including music tracks submitted for review, playlist
              management, profile information, comments, and any other
              contributions to the platform (“Your Content”) and by sharing Your
              Content with Service Provider and its users, you represent that
              you possess the necessary rights to do so. You are responsible for
              specifying the licensing terms under which you are providing Your
              Content. Importantly, you retain ownership of Your Content. When
              you post Your Content on the Site, you are granting Service
              Provider a non-exclusive, irrevocable, worldwide, royalty-free
              license to utilize, reproduce, distribute, sublicense, adapt,
              modify, and publish Your Content. However, this license to Service
              Provider will not supersede any terms established in an
              open-source license that you explicitly apply to Your Content. By
              using PitchPlaylists, you agree not to upload or share any User
              Content that infringes upon third-party rights, violates
              applicable laws, or is otherwise inappropriate or harmful.
              PitchPlaylists reserves the right to moderate and remove any User
              Content that violates these terms.
            </Typography>
            <Typography className="terms-about-text">
              User Content Management and Removal: Account removal and deletion
              of all personal data can be performed directly through the
              Pitchplaylists website. Due to full integration with the Spotify
              API, certain actions, such as managing your account, must be done
              through Spotify settings. To remove your data, log in to your
              Pitchplaylists account through the website, navigate to the
              'Account Settings' section, select the option to delete your
              account and all associated data, and follow the prompts to confirm
              your decision. If applicable, manage and remove data linked with
              your Spotify account through Spotify settings. For any inquiries
              or requests regarding your data, including data removal, please
              refer to our Privacy Policy or contact us at{" "}
              <Link
                to="mailto:info@pitchplaylists.com"
                className="text-color-primary"
              >
                info@pitchplaylists.com
              </Link>
              .
            </Typography>{" "}
            <Typography className="terms-about-text">
              When you delete your User Content, PitchPlaylists will make
              reasonable efforts to permanently remove all associated
              information, content, and end-user data from your account.
              However, please note that due to technical reasons or legal
              obligations, some PitchPlaylists Service Data may not be
              immediately removable or retrievable. it is possible that Your
              Content may still be accessible on the Site. The User acknowledges
              and consents to the Service Provider's exemption from liability
              for any damage or loss with respect to user content. And agrees
              that PitchPlaylists is not responsible or liable for the removal
              or deletion (or failure thereof) of any User Content or End User
              Data.
            </Typography>
            <Typography className="terms-sub-title">
              Eligibility; Accounts
            </Typography>
            <Typography className="terms-about-text">
              THE WEBSITE IS NOT AVAILABLE TO: (A) ANY USERS PREVIOUSLY
              SUSPENDED OR REMOVED FROM THE WEBSITE BY US, OR (B) ANY PERSONS
              UNDER THE AGE OF 18.
              {/* THE WEBSITE IS NOT AVAILABLE TO: (A) ANY USERS PREVIOUSLY
              SUSPENDED OR REMOVED FROM THE WEBSITE BY US, OR (B) ANY PERSONS
              UNDER THE AGE OF 13 WHOSE REGISTRATION HAS NOT BEEN APPROVED BY A
              LEGAL PARENT OR GUARDIAN. */}
            </Typography>
            <Typography className="terms-sub-title">
              Account Passwords and Security.
            </Typography>
            <Typography className="terms-about-text">
              You are solely responsible for maintaining the confidentiality of
              your account and password, and you agree to accept responsibility
              for all activities that occur under your account or password. You
              agree not to impersonate or misrepresent your affiliation with any
              person or entity, including using another person’s username,
              password, or other account information, or another person’s name
              or likeness, or provide false details for a parent or guardian.
              You also agree that you will ensure that this information is kept
              accurate and up-to-date at all times. If you have reason to
              believe that your account is no longer secure (e.g., in the event
              of a loss, theft, or unauthorized disclosure or use of your
              account ID or password), then you agree to immediately notify Us
              at{" "}
              <Link
                to="mailto:info@pitchplaylists.com"
                className="text-color-primary"
              >
                info@pitchplaylists.com
              </Link>{" "}
              You may be liable for the losses incurred by Us or others due to
              any unauthorized use of your Services account.
            </Typography>{" "}
            <Typography className="terms-sub-title">
              Electronic Agreement.
            </Typography>
            <Typography className="terms-about-text">
              This Agreement is an electronic contract that sets out the legally
              binding terms of your use of the Websites and the Service. This
              Agreement may be modified by Us from time to time, such
              modifications to be effective upon posting by Us on the Websites.
              By accessing and/or using the Websites or becoming a Member, you
              accept this Agreement and agree to the terms, conditions, and
              notices contained or referenced herein.
            </Typography>
            <Typography className="terms-sub-title">
              Electronic Notice.
            </Typography>
            <Typography className="terms-about-text">
              You consent to receive notices, including agreements, disclosures,
              and other communications, electronically from us at the email
              address you have provided. You agree that these electronic notices
              satisfy any legal requirements that such communications be in
              writing.
            </Typography>
          </Box>
          <Box>
            <Typography className="terms-sub-heading">
              2. Site Services
            </Typography>
            <Typography className="terms-about-text">
              PitchPlaylists is a comprehensive playlist management platform
              designed to empower playlist curators and artists by streamlining
              the process of music submission and discovery. Curators can
              efficiently manage their playlists, review submissions, and
              respond to artists with a simple "accept" or "reject" message. The
              platform creates a collaborative space where artists can showcase
              their music directly to curators, fostering connections and
              enhancing music discovery.
            </Typography>
            <Typography className="terms-about-text">
              With Spotify account integration, users can access robust tools
              for managing playlists and optimizing their content. Artists can
              expand their reach by submitting their tracks, while curators
              benefit from efficient playlist and submission management
              features. The platform’s technology simplifies campaign
              management, improving audience engagement and connecting music
              creators with curators.
            </Typography>
            <Typography className="terms-about-text">
              1. Landing Pages: Registered users who have added a playlist to
              the website can access a public page showcasing their content.
              These pages are designed to engage audiences through social
              networking services, helping curators connect with their target
              listeners and artists.
            </Typography>
            <Typography className="terms-about-text">
              2. Access to End User Data: To retrieve End User Data from your
              Third Party Accounts (e.g., Spotify), you agree to provide
              necessary security permissions, software interfaces, and any
              requested information to the platform. The platform is not
              responsible for Third Party Accounts or any products/services
              accessed from them on your behalf. You indemnify the platform from
              liability related to accessing End User Data from your Third Party
              Accounts.
            </Typography>
            <Typography className="terms-about-text">
              3. Controller of End User Data: You as the user (not the
              platform), determine what End User Data (including PII) is
              received and stored by the platform through your use of the
              Services. You agree to comply with applicable privacy and data
              security laws, describing how End User Data is collected, used,
              and shared. Compliance with applicable laws, including obtaining
              necessary consent from End Users, is required.
            </Typography>
            {/* <Typography className="terms-about-text">
              4. Contest Landing Pages: Users can create Contest Landing Pages
              through the Marketing Service to engage their audience. Specific
              details about each type of Landing Page can be found on feature
              pages provided by the platform.
            </Typography> */}
            <Typography className="terms-about-text">
              4. Interaction with Consumers: Consumers engaging with Landing
              Pages may provide personally identifiable information (PII), which
              the platform collects and shares with you, the Registered User who
              created the Landing Pages. You agree to comply with platform's
              Privacy Policy and applicable privacy and data security laws and
              only interact with consumers who have consented to such
              interactions.
            </Typography>
            <Typography className="terms-about-text">
              5. Integration with Third-Party Services: When you integrate or
              link third-party services with the platform, such as Spotify or
              email service providers, the platform may receive certain
              information about you. This includes authentication details (like
              name and email from Spotify), content sharing permissions, and
              data access permissions as controlled by the third-party service's
              privacy settings and policies.
            </Typography>
          </Box>
          {/* <Box>
            <Typography className="terms-sub-heading">
              3. Credit Purchases
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">3.1.</span> Users have the
              option to purchase credits, which enable them to submit their
              songs to playlists beyond the free weekly limit. The delivery of
              these credits occurs immediately upon the successful completion of
              the payment process. Once payment is confirmed and processed, the
              credits are added to the user's account, allowing them to continue
              submitting their music without interruption. PitchPlaylists
              ensures a seamless and efficient credit purchasing experience,
              providing users with the necessary tools to maximize their
              playlist submissions and increase their chances of gaining more
              listeners. PitchPlaylists reserves the right to modify pricing
              without prior user approval or notice.
            </Typography>
            <Typography className="terms-sub-title">3.2. Credits</Typography>
            <Typography className="terms-about-text">
              a. Credits allow users to expand their music submissions beyond 20
              playlists per week. Users start with 20 free credits weekly,
              enabling them to submit songs without cost.
            </Typography>
            <Typography className="terms-about-text">
              b. <span className="terms-sub-title">Additional credits:</span>{" "}
              Can be purchased for increased submission capacity. Details such
              as the cost of credits and additional terms can be found on our
              credit purchase page.
            </Typography>
            <Typography className="terms-about-text">
              c. <span className="terms-sub-title">Regular Refills:</span> To
              ensure continuous engagement, we refill 20 free credits to all
              users every week. This regular replenishment enables users to
              consistently submit their music, maintaining active participation
              on our platform.
            </Typography>
            <Typography className="terms-about-text">
              d. <span className="terms-sub-title">Usage:</span> Credits can be
              managed directly through our user-friendly interface, where users
              can track their credit balance, purchase additional credits as
              needed, and manage their music submission activities seamlessly.
            </Typography>
            <Typography className="terms-about-text">
              e. <span className="terms-sub-title">Expiration:</span>{" "}
              PitchPlaylists reserves the right to remove unused credits after
              12 months.
            </Typography>
          </Box> */}
          <Box>
            <Typography className="terms-sub-heading">
              3. Artist Representation
            </Typography>
            <Typography className="terms-about-text">
              If you are an advertising agency, label, promoter, or entity
              representing an “Artist” (defined as Artist refers to the
              individual, band, or entity on whose behalf you are using the
              Service, and who owns the rights to the content provided.) the
              following terms apply to you:
            </Typography>
            <Typography className="terms-sub-title">
              <span className="terms-sub-title">
                4.1. User Representations, Warranties, and Covenants that:
              </span>
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">a. Authorization:</span> You
              represent that you are the authorized representative (e.g., label,
              promoter, distributor) of the Artist. You have the legal authority
              to enter into this Agreement, purchase Services on behalf of the
              Artist, make decisions, and carry out actions relating to the
              Artist's accounts on the Service.
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">
                b. Agreement with the Artist:
              </span>{" "}
              You agree to have or will enter into an agreement with the Artist
              that:<br></br>
              <ul>
                <li>Grants you Agency Right to represent the Artist.</li>
                <li>
                  Binds the Artist to terms as protective of PitchPlaylists as
                  this Agreement.
                </li>
                <li>
                  Designates PitchPlaylists as an intended third-party
                  beneficiary regarding User campaigns delivered on the Service.
                </li>
              </ul>
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">c. Limitations: </span>
              Without prior written consent from PitchPlaylists, you agree not
              to:<br></br>
              <ul>
                <li>
                  Make commitments to the Artist beyond the obligations under
                  this Agreement.
                </li>
                <li>
                  Negotiate terms or conditions related to the Service that are
                  inconsistent with this Agreement.
                </li>
              </ul>
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">d. Termination:</span> Upon
              termination, if either the artist or curator violates the
              platform's rules, their account will be banned. All data related
              to the account will be removed, and the user will no longer have
              access to the platform or any associated content.
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">
                e. Breach Indemnification:
              </span>{" "}
              Any violation of this Agreement by you will be deemed a breach.
              You agree to indemnify, defend, and hold PitchPlaylists harmless
              from all damages, liabilities, costs, and expenses incurred as a
              result of such violation.
            </Typography>
          </Box>
          <Box>
            <Typography className="terms-sub-heading">
              4. Campaigns and Deliverables
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">
                User-generated Content Responsibility:
              </span>
              <br></br>
              Users acknowledge that content uploaded or made available on the
              PitchPlaylists platform may be used by other users for various
              purposes, including placing tracks in playlists, writing reviews,
              and other forms of user-generated content. PitchPlaylists and its
              affiliates do not assume responsibility for such user-generated
              content, including any claims or losses arising from its use,
              whether on the platform or elsewhere.
            </Typography>
            <Typography className="terms-about-text">
              {" "}
              <span className="terms-sub-title">Performance Guarantees:</span>
              <br></br>
              PitchPlaylists does not guarantee the specific reach or
              performance metrics of user campaigns, including the number of
              reviews, song streams, or achievement of campaign objectives as a
              result of content uploaded or made available via the service.
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">Review Activity Control:</span>
              <br></br>
              While PitchPlaylists employs systems to monitor and filter certain
              review activities, it does not guarantee the authenticity or
              quantity of reviews generated on user campaigns. PitchPlaylists is
              not liable for review fraud, technological issues, or other
              invalid review activities that may impact campaign performance.
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">
                Delivery Guarantee and Remedies:
              </span>
              <br></br>
              PitchPlaylists does not guarantee flawless campaign delivery. In
              the event that a campaign is not delivered according to the terms
              of the agreement, PitchPlaylists may provide remedies at its
              discretion. PitchPlaylists has no obligation to continue
              delivering results after a campaign has ended due to user breach.
              Additionally, PitchPlaylists is not responsible for delays in
              campaign deliverables caused by the user.
            </Typography>
          </Box>
          {/* <Box>
            <Typography className="terms-sub-heading">
              6. Payment Terms
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">
                Payment Authorization and Billing:
              </span>{" "}
              If User opts to pay using a credit card, other payment cards, or
              alternative methods available within the PitchPlaylists platform,
              User authorizes PitchPlaylists or its designated third-party
              payment processor to charge the specified payment method for
              applicable fees at PitchPlaylists's discretion. Prior to processing
              charges, PitchPlaylists or its processor may conduct one or more
              payment authorization checks. PitchPlaylists may also periodically
              update User's payment information using a card updater service.
              User grants PitchPlaylists and its payment processor permission to
              store and continue billing the designated payment method until the
              ordered campaign is fulfilled. In case of dispute related to
              charges, you must let PitchPlaylists know within sixty (60) days
              after the date that PitchPlaylists charges you.
            </Typography>
            <Typography className="terms-about-text">
              {" "}
              <span className="terms-sub-title">Non-Refundable Charges: </span>
              All fees paid by User are non-refundable. If PitchPlaylists cancels
              services at its sole discretion, PitchPlaylists may issue a full or
              partial refund for payments already processed. Refunds may take
              several business days to reflect in User's account. User
              acknowledges that this refund policy constitutes User’s sole and
              exclusive remedy, and User is responsible for all fees associated
              with completed purchases prior to cancellation, which may be
              charged to User's payment method.
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">
                Credit Billing and Renewal:
              </span>{" "}
              User will be billed upon credit order submission. PitchPlaylists
              may or may not notify User in advance of any price changes
              effective at the start of the subsequent purchase period. User
              agrees to accept the then-current price as permitted by applicable
              law. If User cancels a Purchase, no refunds will be provided for
              amounts already paid.
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">
                Taxes and Additional Charges:
              </span>{" "}
              Invoiced amounts and other payments due to PitchPlaylists exclude
              applicable taxes, duties, levies, or governmental charges (e.g.,
              sales, use, value-added, withholding, excise taxes). User is
              responsible for remitting all taxes to the relevant taxing
              authority. Interest accrues on past due amounts at the lower of 1%
              per month or the maximum lawful rate. In cases of failed payment
              methods, PitchPlaylists may use alternative collection methods,
              with User agreeing to cover associated collection expenses,
              including reasonable legal fees. Should User pay any amounts with
              a credit card and the card issuer seeks recovery from
              PitchPlaylists, User agrees to promptly reimburse PitchPlaylists for
              all necessary amounts and related costs incurred.
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">
                Campaign Performance Reporting:{" "}
              </span>
              PitchPlaylists will provide User with performance metrics related
              to active campaigns. User acknowledges that reported performance
              metrics are provided for User’s convenience only and do not
              constitute guarantees. PitchPlaylists does not warrant the accuracy
              of these metrics and is not bound by any information provided in
              performance reports.
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">
                Payment Information Security:
              </span>{" "}
              When users provide card details to Us, we take diligent measures
              to safeguard this information. However, We do not accept liability
              or responsibility for any damages, losses, or adverse consequences
              related to the use, protection, or handling of these card details.
              Users acknowledge and expressly accept that transmitting and
              storing card details inherently involves risks, and they willingly
              assume all associated risks and liabilities. In the case of
              suspected or fraudulent payment, including the use of stolen
              credentials, by anyone, or any other fraudulent activity, We
              reserve the right to block a User's Account. We shall be entitled
              to inform any relevant authorities or entities (including credit
              reference agencies) of any payment fraud or other unlawful
              activity and may employ collection services to recover payments.
              We reserve the right to run credit checks on any User on the basis
              of the information provided to us on registration.
            </Typography>{" "}
            <Typography className="terms-about-text">
              <span className="terms-sub-title">
                Changes to Price Terms for Tournaments
              </span>{" "}
              <br></br>
              We retain the right to alter Tournament pricing terms at any time
              and will provide advance notice of such changes. These pricing
              term adjustments are not retroactive and will solely affect
              Tournament renewals after the modified pricing terms have been
              communicated.
            </Typography>
          </Box> */}
          <Box>
            <Typography className="terms-sub-heading">
              5. Modification and Termination
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">
                Termination by Either Party:
              </span>{" "}
              Either PitchPlaylists or the user can terminate the agreement.
              This can be done through the Spotify “Settings page” or by
              providing written notice to the other party at least 15 days in
              advance. Termination can occur even without a major breach of the
              agreement without prior notice. We reserve the right to close your
              Account (including your login and password) for any reason,
              including, but not limited to, violation of any terms or
              conditions of this Agreement, breach of any of the Terms, or if
              your use of the Services is deemed improper or breaches the spirit
              of this Agreement with or without prior notice.
            </Typography>
            {/* <Typography className="terms-about-text">
              {" "}
              <span className="terms-sub-title">
                Suspension of Campaign Delivery:{" "}
              </span>
              PitchPlaylists reserves the right to immediately suspend the
              delivery of user campaigns if the user fails to make timely
              payments as required under the agreement. If such a suspension
              occurs, the end date of the campaign specified in the purchase
              will be extended by the duration of the suspension period.
            </Typography> */}
            <Typography className="terms-about-text">
              <span className="terms-sub-title">
                Termination Due to Inactive Accounts:
              </span>{" "}
              Additionally, PitchPlaylists may terminate the agreement if the
              user's account remains inactive (See Section 3.4.e).
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">Survival:</span> After
              termination or expiration, specific sections of the agreement will
              remain in effect. These include provisions related to additional
              liability, campaign performance, failure to deliver campaigns,
              {/* payments and  */}
              reporting, termination, persistence of certain clauses,
              confidentiality, representations, indemnification, limitation of
              liability, disputes, and general terms.
              <Typography className="terms-about-text">
                If We terminate your account because you have breached this
                Agreement, you may not be entitled to any refund if any. All
                decisions regarding the termination of accounts shall be made at
                the sole discretion of the Service provider. You agree that We
                are not required to provide you notice prior to terminating your
                membership and/or subscription. We are not required and may be
                prohibited from disclosing a reason for the termination of your
                account. Even after your account is terminated, this Agreement
                will remain in effect. All terms that by their nature may
                survive termination of this Agreement shall be deemed to survive
                such termination.
              </Typography>
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">Modifications to Service:</span>{" "}
              We reserve the right to modify or discontinue, temporarily or
              permanently, the Service (or any part thereof) with or without
              notice. You agree that We will not be liable to you or to any
              third party for any modification, suspension, or discontinuance of
              the Service.
            </Typography>
          </Box>
          <Box>
            <Typography className="terms-sub-heading">
              6. Third-Party Sites, Products, and Services; Links
            </Typography>
            <Typography className="terms-about-text">
              The Service may provide access to other websites and resources on
              the Internet, which may be offered by third parties. We have no
              control over these sites and resources and do not endorse them.
              You acknowledge and agree that We will not be responsible or
              liable, directly or indirectly, for any damage or loss allegedly
              caused by or connected to the use or reliance on content, events,
              goods, or services available through such sites or resources. Any
              interactions with third parties while using the Service are solely
              between you and the third party. You agree that the Service
              Provider is not liable for any loss or claim you may have against
              any such third party.
            </Typography>
          </Box>
          <Box>
            <Typography className="terms-sub-heading">
              7. PitchPlaylists Control of Service
            </Typography>
            <Typography className="terms-about-text">
              Users acknowledge and agree that PitchPlaylists owns the service
              and is responsible for its design, layout, and ongoing
              maintenance, including the display and performance of any user
              campaigns. PitchPlaylists reserves the right, at its sole
              discretion, to add channels, delete or replace campaign features,
              or modify other areas where user campaigns are displayed, which
              may result in the removal of existing user campaigns.
            </Typography>
            <Typography className="terms-about-text">
              We may, at its sole discretion, reject or remove any user
              campaigns at any time, with or without notice, under the following
              circumstances:
              <br></br>
              <ul>
                <li>
                  Violation of Terms: If a user campaign violates this
                  Agreement, content restrictions, or any other provision in
                  PitchPlaylist's Terms of Service.
                </li>
                <li>
                  Inappropriate Content: If PitchPlaylists determines a user
                  campaign to be inappropriate for any reason within its sole
                  discretion, regardless of previous acceptanc
                </li>
              </ul>
            </Typography>
            <Typography className="terms-about-text">
              If We remove or reject a user campaign, it may notify the user of
              the reasons for such action. The user has the option to revise and
              resubmit the campaign to address PitchPlaylist's concerns.
            </Typography>
          </Box>
          <Box>
            <Typography className="terms-sub-heading">
              8. Intellectual Property Rights
            </Typography>
            <span className="terms-sub-title">Grant of License:</span> User
            grants PitchPlaylists (and its affiliates) a worldwide,
            non-exclusive, royalty-free, fully-paid license to:
            <Typography className="terms-about-text">
              <br></br>
              <ul>
                <li>
                  {" "}
                  <span className="terms-sub-title">
                    Use and Distribute User Materials:
                  </span>{" "}
                  use, reproduce, perform, display, make available, and
                  distribute the content and any related information provided by
                  User (referred to as "User Materials") through the
                  PitchPlaylists Service.
                </li>
                <li>
                  <span className="terms-sub-title">
                    Modify and Create Derivative Works:
                  </span>{" "}
                  may alter, modify, repurpose, or create derivative works of
                  User Materials as necessary or desirable to serve campaigns,
                  newsfeed content, or other content on the PitchPlaylists
                  Service. This includes copyright-protected works and any
                  trademarks, logos, or business identifiers included therein
                  ("Trademarks").
                </li>
              </ul>
            </Typography>
            <Typography className="terms-about-text">
              These licenses are valid for the duration of the respective
              campaign unless otherwise specified in the Agreement.
            </Typography>
            <Typography className="terms-about-text">
              {" "}
              <span className="terms-sub-title">Post-Campaign Use: </span>
              During the term of the Agreement, PitchPlaylists may factually
              reference User Campaigns, the User's name and logo, as well as the
              type and dates of campaigns conducted, in a respectful and
              non-disparaging manner. PitchPlaylists may also use performance
              data to discuss campaign results and reference publicly available
              information about the User on or off the PitchPlaylists Service.
            </Typography>{" "}
            <Typography className="terms-about-text">
              {" "}
              <span className="terms-sub-title">Retention of Rights: </span>
              User retains all rights to User Campaigns, including all User
              Trademarks and related intellectual property rights embodied
              therein. Upon termination of the Agreement, all rights granted to
              PitchPlaylists regarding User campaigns cease, and these rights
              revert to User, except as otherwise provided in the Agreement.
            </Typography>
            <Typography className="terms-about-text">
              {" "}
              <span className="terms-sub-title">
                Continued Use of User Campaigns and Trademarks:{" "}
              </span>
              Notwithstanding termination of the Agreement, User acknowledges
              that User campaigns and Trademarks displayed on the PitchPlaylists
              Service may continue to be used and displayed. This continued
              usage may occur indefinitely if User campaigns or Trademarks have
              been incorporated into user profiles, news feeds, or other
              features of the PitchPlaylists Service.{" "}
            </Typography>
          </Box>
          <Box>
            <Typography className="terms-sub-heading">
              9. Confidentiality
            </Typography>
            <Typography className="terms-about-text">
              Users acknowledge that during the contract term, User may obtain
              access to confidential information about Our business, including,
              but not limited to computer programs, inventions, drawings, notes,
              writings, experimental work, business strategies, and/or corporate
              know-how (“Confidential Information”). The User agrees to use
              reasonable care and adequate measures to protect the Confidential
              Information from disclosure. The User agrees not to make known, or
              permit such Confidential Information to be made known, to any
              person or entity.
            </Typography>
            <Typography className="terms-about-text">
              In the event that a User is required by law to disclose the
              Confidential Information, compliance with such lawful legal
              process shall not constitute breach of this Agreement. Users
              retain the right to disclose the Confidential Information by
              operation of law. If legally permissible and to the extent
              possible, the User will give prior notice to Us of such
              disclosure, so that We at Our discretion, may seek confidential or
              protected status for such Confidential Information. This clause
              shall survive the termination of this Agreement and continue for a
              period of two years. Upon termination of the Agreement, the User
              agrees to return or destroy all Confidential Information received
              from Us. The Parties may supplement this confidentiality provision
              through a separate non-disclosure agreement.
            </Typography>
          </Box>
          <Box>
            <Typography className="terms-sub-heading">
              10. Indemnification
            </Typography>
            <Typography className="terms-sub-title">
              Representations and Warranties by Each Party:<br></br>{" "}
              <Typography className="terms-about-text">
                <ul>
                  <li>
                    Each party represents and warrants that:
                    <ul>
                      <li>
                        It is duly organized, validly existing, and in good
                        standing under the laws of its jurisdiction.
                      </li>
                      <li>
                        The execution and performance of the Agreement are
                        within its corporate powers and duly authorized.
                      </li>
                      <li>
                        Its performance under the Agreement will not result in a
                        breach of any other agreements or obligations.
                      </li>
                    </ul>
                  </li>
                </ul>
              </Typography>
            </Typography>
            <Typography className="terms-sub-title">
              User's Additional Representations and Warranties to
              PitchPlaylists:
              <br></br>{" "}
              <Typography className="terms-about-text">
                <ul>
                  <li>
                    User further represents and warrants to PitchPlaylists that:
                    <ul>
                      <li>
                        User campaigns do not violate PitchPlaylist's Terms of
                        Service or any laws and are not unlawful, defamatory,
                        obscene, or infringing on any third-party rights.
                      </li>
                      <li>
                        User will comply with all applicable laws and
                        regulations in performing under the Agreement.
                      </li>
                    </ul>
                  </li>
                </ul>
              </Typography>
            </Typography>{" "}
            <Typography className="terms-about-text">
              You agree, to the extent permissible under your state’s laws, to
              indemnify, defend, and hold harmless Service Provider, and its
              parent, successors, affiliated companies, contractors, officers,
              directors, employees, agents and its third-party suppliers,
              licensors, and partners (“Parties, Partners") from and against all
              losses, damages, liabilities, demands, judgments, settlements,
              costs and expenses of any kind (including legal fees and
              expenses), from any claim or demand made by any third-party
              relating to or arising out of (i) your access to, use or misuse of
              the Services; (ii) your breach or alleged breach of these Terms,
              or any violation of the Terms; (iii) any breach of the
              representations, warranties, and covenants made herein, whether by
              you or by any User; (iv) your failure to comply with Applicable
              Laws(including any failure to obtain or provide any necessary
              consent or notice); (v) the infringement by you or any third-party
              using your account of any intellectual property, privacy, or other
              right of any person or entity, including in connection with your
              User Content, or (vi) your breach or alleged breach of any
              interaction, agreement, or policy between you and any other Users.
              Service Provider reserves the right, at your expense, to assume
              the exclusive defense and control of any matter for which you are
              required to indemnify Service Provider, and you agree to cooperate
              with Service Provider’s defense of these claims. You agree not to
              settle any such matter without the prior written consent of the
              Service Provider. We will use reasonable efforts to notify you of
              any such claim, action, or proceeding upon becoming aware of it.
            </Typography>
          </Box>
          <Box>
            <Typography className="terms-sub-heading">
              11. Disclaimers
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">No Warranties</span> <br></br>
              THE WEBSITE, AND ALL DATA, INFORMATION, SOFTWARE, WEBSITE
              MATERIALS, CONTENT (WHETHER OWNED OR LICENSED), USER CONTENT,
              REFERENCE SITES, SERVICES, OR APPLICATIONS MADE AVAILABLE IN
              CONJUNCTION WITH OR THROUGH THE WEBSITE (THE "SERVICE PROVIDER
              OFFERINGS"), ARE PROVIDED ON AN “AS IS,” “AS AVAILABLE,” AND “WITH
              ALL FAULTS” BASIS. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO
              APPLICABLE LAW, THE SERVICE PROVIDER DISCLAIMS ANY AND ALL
              WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER STATUTORY, EXPRESS
              OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ALL IMPLIED WARRANTIES
              OF MERCHANTABILITY, QUALITY, AVAILABILITY, QUIET ENJOYMENT,
              FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. NO
              ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
              FROM US OR THROUGH THE WEBSITE WILL CREATE ANY WARRANTY NOT
              EXPRESSLY STATED HEREIN. WE RESERVE THE RIGHT TO ALTER OR CEASE
              PORTIONS OR THE ENTIRETY OF THE WEBSITE OR ANY OF ITS CONTENT AT
              ANY GIVEN TIME.
            </Typography>
            <Typography className="terms-about-text">
              {" "}
              <span className="terms-sub-title">Content </span>
              <br></br>
              SERVICE PROVIDER, DOES NOT WARRANT THAT THE WEBSITE OR ANY DATA,
              USER CONTENT, FUNCTIONS, OR ANY OTHER INFORMATION OFFERED ON OR
              THROUGH THE WEBSITE WILL BE UNINTERRUPTED, OR FREE OF ERRORS,
              VIRUSES, OR OTHER HARMFUL COMPONENTS, AND DOES NOT WARRANT THAT
              ANY OF THE FOREGOING WILL BE CORRECTED. SERVICE PROVIDER MAKES NO
              REPRESENTATION OR WARRANTY THAT (1) THE SERVICE PROVIDER OFFERINGS
              WILL (A) MEET YOUR REQUIREMENTS OR EXPECTATIONS, OR BE TO YOUR
              LIKING, OR (B) WILL BE TIMELY, SECURE, ACCURATE, FREE FROM ERRORS
              OR LOSS, OR UNINTERRUPTED, (2) THAT THE SERVICES ARE FREE FROM
              VIRUSES OR OTHER HARMFUL COMPONENTS, OR (3) THAT ANY DEFECTS OR
              ERRORS WILL BE CORRECTED. SOME FEATURES MAY BE NEW OR EXPERIMENTAL
              AND MAY NOT HAVE BEEN TESTED IN ANY MANNER.
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">Harm to Your Computer</span>{" "}
              <br></br>
              YOU UNDERSTAND AND AGREE THAT YOUR USE, ACCESS, DOWNLOAD, OR
              OTHERWISE OBTAINING OF CONTENT, WEBSITE MATERIALS, SOFTWARE, OR
              DATA THROUGH THE WEBSITE (INCLUDING THROUGH ANY API’S) IS AT YOUR
              OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE
              FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM)
              OR LOSS OF DATA THAT RESULTS THEREFROM.
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">
                Limitations by Applicable Law
              </span>{" "}
              <br></br> SOME STATES OR OTHER JURISDICTIONS DO NOT ALLOW THE
              EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT
              APPLY TO YOU. YOU MAY ALSO HAVE OTHER RIGHTS THAT VARY FROM STATE
              TO STATE AND JURISDICTION TO JURISDICTION.
            </Typography>
          </Box>
          <Box>
            <Typography className="terms-sub-heading">
              12. Limitation of Liability and Damages
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">Limitation of Liability</span>{" "}
              <br></br>
              UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE,
              WILL SERVICE PROVIDER OR ITS PARTNERS BE LIABLE FOR ANY SPECIAL,
              INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, RELIANCE, OR
              EXEMPLARY DAMAGES (INCLUDING WITHOUT LIMITATION DAMAGES ARISING
              FROM ANY UNSUCCESSFUL COURT ACTION OR LEGAL DISPUTE, LOST
              BUSINESS, LOST REVENUES OR PROFITS, LOSS OF DATA, OR ANY OTHER
              PECUNIARY OR NON-PECUNIARY LOSS OR DAMAGE OF ANY NATURE
              WHATSOEVER) EVEN IF SERVICE PROVIDER OR ITS PARTNERS HAS BEEN
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES ARISING OUT OF OR
              RELATING (i) TO THE TERMS; (ii) YOUR USE OF (OR INABILITY TO USE)
              THE WEBSITE OR SERVICE PROVIDER OR ITS PARTNERS OFFERINGS, OR
              (iii) ANY OTHER INTERACTIONS WITH SERVICE PROVIDER OR ANY
              THIRD-PARTY THROUGH OR IN CONNECTION WITH THE SERVICE PROVIDER
              OFFERINGS, INCLUDING OTHER USERS, APPLICABLE LAW MAY NOT ALLOW THE
              LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR
              CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY
              NOT APPLY TO YOU. IN SUCH CASES, SERVICE PROVIDER’S LIABILITY WILL
              BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
            </Typography>
            <Typography className="terms-about-text">
              {" "}
              <span className="terms-sub-title">Limitation of Damages </span>
              <br></br>
              IN NO EVENT WILL SERVICE PROVIDER OR SERVICE PROVIDER PARTNERS
              TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF
              ACTION ARISING OUT OF OR RELATING TO THE TERMS OR YOUR USE OF THE
              WEBSITE OR YOUR INTERACTION WITH OTHER WEBSITE USERS (WHETHER IN
              CONTRACT, TORT (INCLUDING NEGLIGENCE), WARRANTY, OR OTHERWISE),
              EXCEED THE AMOUNT PAID BY YOU TO SERVICE PROVIDER, IF ANY, FOR
              ACCESSING THE WEBSITE DURING THE TWELVE MONTHS IMMEDIATELY
              PRECEDING THE DATE OF THE CLAIM OR FIVE HUNDRED DOLLARS ($500).
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">Basis of the Bargain</span>{" "}
              <br></br>
              YOU ACKNOWLEDGE AND AGREE THAT SERVICE PROVIDER HAS OFFERED THE
              WEBSITE AND ENTERED INTO THE TERMS IN RELIANCE UPON THE
              DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH HEREIN,
              REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN YOU AND
              SERVICE PROVIDER, AND THAT THE DISCLAIMERS AND THE LIMITATIONS OF
              LIABILITY SET FORTH HEREIN FORM AN ESSENTIAL BASIS OF THE BARGAIN
              BETWEEN YOU AND SERVICE PROVIDER. SERVICE PROVIDER WOULD NOT BE
              ABLE TO PROVIDE THE WEBSITE TO YOU ON AN ECONOMICALLY REASONABLE
              BASIS WITHOUT THESE LIMITATIONS.
              <Typography className="terms-about-text">
                SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF
                CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY
                FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF
                THE ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU OR BE
                ENFORCEABLE WITH RESPECT TO YOU. IF YOU ARE DISSATISFIED WITH
                ANY PORTION OF THE SERVICE OR WITH THESE TERMS OF SERVICE, YOUR
                SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE THE USE OF THE
                SERVICE.{" "}
              </Typography>
              <Typography className="terms-about-text">
                IF YOU ARE A USER FROM NEW JERSEY, THE FOREGOING SECTIONS TITLED
                “DISCLAIMER, NO WARRANTIES” AND “LIMITATION OF LIABILITY” ARE
                INTENDED TO BE ONLY AS BROAD AS IS PERMITTED UNDER THE LAWS OF
                THE STATE OF NEW JERSEY. IF ANY PORTION OF THESE SECTIONS IS
                HELD TO BE INVALID UNDER THE LAWS OF THE STATE OF NEW JERSEY,
                THE INVALIDITY OF SUCH PORTION SHALL NOT AFFECT THE VALIDITY OF
                THE REMAINING PORTIONS OF THE APPLICABLE SECTIONS.
              </Typography>
            </Typography>
          </Box>{" "}
          <Box>
            <Typography className="terms-sub-heading">
              13.Miscellaneous (Including Dispute Resolution and Arbitration)
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">Force Majeure</span>
              <br></br>
              Neither party will be liable for any failure or delay in
              performance due to circumstances beyond its reasonable control,
              including, but not limited to, natural disasters, acts of God,
              government actions, or wars.
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">Waiver</span>
              <br></br>
              The failure of Service Provider to exercise or enforce any right
              or provision of the Terms will not constitute a waiver of such
              right or provision. Any waiver of any provision of the Terms will
              be effective only if in writing and signed by Us.
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">Severability</span> <br></br>
              If any provision of the Terms or any Guidelines is held to be
              unlawful, void, or for any reason unenforceable, then that
              provision will be limited or eliminated from the Terms to the
              minimum extent necessary and will not affect the validity and
              enforceability of any remaining provisions.
            </Typography>
            <Typography className="terms-about-text">
              <span className="terms-sub-title">Assignment</span> <br></br>
              The Terms and related Guidelines, and any rights and licenses
              granted hereunder, may not be transferred or assigned by you
              without the Service Provider’s prior written consent but may be
              assigned by Us without consent or any restriction. Any assignment
              attempted to be made in violation of the Terms shall be null and
              void.
            </Typography>{" "}
            <Typography className="terms-about-text">
              <span className="terms-sub-title">Survival</span> <br></br>
              Upon termination of the Terms, any provision which, by its nature
              or express terms should survive, will survive such termination or
              expiration, including, but not limited to, Sections (Privacy
              Policy), (Modification of the Terms), (Proprietary Materials),
              (Confidentiality) (Intellectual Property Right), (Assignment),
              (Miscellaneous (Including Dispute Resolution and Arbitration)).
            </Typography>{" "}
            <Typography className="terms-about-text">
              <span className="terms-sub-title">Electronic Notice.</span>{" "}
              <br></br>
              You consent to receive notices, including agreements, disclosures,
              and other communications, electronically from us at the email
              address you have provided. You agree that these electronic notices
              satisfy any legal requirements that such communications be in
              writing.
            </Typography>{" "}
            <Typography className="terms-about-text">
              <span className="terms-sub-title">
                Dispute Resolution and Arbitration
              </span>{" "}
              <br></br>
              PLEASE READ THIS SECTION CAREFULLY BECAUSE IT AFFECTS YOUR RIGHTS.
              BY AGREEING TO BINDING ARBITRATION, YOU WAIVE YOUR RIGHT TO
              LITIGATE DISPUTES THROUGH A COURT AND TO HAVE A JUDGE OR JURY
              DECIDE YOUR CASE.
              <ol type="A">
                <li>
                  Generally. In order to expedite and control the cost of
                  disputes, Service Provider and you agree that any legal or
                  equitable claim, dispute, action or proceeding arising from or
                  related to your use of the Services or these Terms (“Dispute”)
                  will be resolved as follows to the fullest extent permitted by
                  law
                </li>
                <li>
                  Binding Arbitration. Any Dispute that has not been resolved by
                  negotiation as provided herein within sixty (60) days or such
                  time period as you and the Service Provider may otherwise
                  agree, shall be finally resolved by binding arbitration as
                  described in (Dispute Resolution and Arbitration). All
                  Disputes will be resolved before a neutral arbitrator, whose
                  decision will be final except for a limited right of appeal
                  under the Applicable Arbitration Act. The place of arbitration
                  shall be Service Provider's jurisdiction. Any court with
                  jurisdiction over the parties may enforce the arbitrator’s
                  award.
                </li>
                <li>
                  Class Action Waiver. Any proceedings to resolve or litigate
                  any Dispute in any forum will be conducted solely on an
                  individual basis. Neither you nor the Service Provider will
                  seek to have any Dispute heard as a class action or in any
                  other proceeding in which either party acts or proposes to act
                  in a representative capacity. No arbitration or proceeding
                  will be combined with another without the prior written
                  consent of all parties to all affected arbitrations or
                  proceedings. You may file a Dispute only on your own behalf
                  and cannot seek relief that would affect other Users. If there
                  is a final judicial determination that any particular Dispute
                  cannot be arbitrated in accordance with the limitations of
                  (Dispute Resolution and Arbitration), then only that Dispute
                  may be severed and brought in court. All other Disputes remain
                  subject to (Dispute Resolution and Arbitration).
                </li>
                <li>
                  Confidentiality: All aspects of the arbitration proceeding,
                  and any ruling, decision, or award by the arbitrator, will be
                  strictly confidential for the benefit of all parties.
                </li>
              </ol>
            </Typography>{" "}
            <Typography className="terms-about-text">
              <span className="terms-sub-title">Jurisdiction </span> <br></br>
              These terms, as well as any claim, controversy, or dispute arising
              from or related to these terms, are interpreted and controlled
              according to the laws of the Service Provider Jurisdiction without
              giving effect to any conflicts of laws provision. In the event
              that one or more provisions within these terms are deemed invalid,
              unlawful, or unenforceable in any manner by a court with
              appropriate jurisdiction, such determination will not affect the
              validity, legality, or enforceability of the remaining provisions.
            </Typography>{" "}
            <Typography className="terms-about-text">
              <span className="terms-sub-title">Entire Agreement </span>{" "}
              <br></br>
              The Terms, the Privacy Policy, and Acceptable Use Guidelines
              constitute the entire agreement between you and the Service
              Provider relating to the subject matter herein and will not be
              modified except consented by both parties or by a change to the
              Terms, Privacy Policy or Acceptable Use Guidelines made by Service
              Provider as set forth in Section (Modification of the Terms)
              above.
            </Typography>{" "}
          </Box>{" "}
          <Box>
            <Typography className="terms-sub-heading">
              14. Your Privacy
            </Typography>
            <Typography className="terms-about-text">
              We respect the privacy of our users. For details please see our
              Privacy Policy. By using the Service, you consent to our
              collection and use of personal data as outlined therein.
            </Typography>
          </Box>{" "}
          <Box>
            <Typography className="terms-sub-heading">
              15. Contact Information
            </Typography>
            <Typography className="terms-about-text">
              Do you have any questions, concerns, or suggestions? Please don't
              hesitate to reach out to us at{" "}
              <Link
                to="mailto:info@pitchplaylists.com"
                className="text-color-primary"
              >
                info@pitchplaylists.com
              </Link>{" "}
              If you encounter any violations of our Terms of Service or need
              clarification about these terms or our services, we're here to
              assist you.
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
