import { Box, Chip, Grid, Typography, useMediaQuery } from "@mui/material";

import { themeColors, themeFonts } from "../../configs";
import { SearchComponents } from "../../components/filter/search-component";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetArtistListQuery } from "../../components/apis/artistsApi";
import { ROUTES } from "../../components/consts/routes.consts";
import {
  Artist1,
  Artist2,
  Artist3,
  Artist6,
  Artist4,
  Artist5,
} from "../../pngs";

export const HomePageSection = () => {
  const isSmallScreen = useMediaQuery("(max-width:1184px)");
  const isMediumScreen = useMediaQuery("(max-width:1500px)");
  const isExtraSmallScreen = useMediaQuery("(max-width:1100px)");
  const xxs = useMediaQuery("(max-width:745px)");
  const isMiniScreen = useMediaQuery("(max-width:500px)");
  const homepageSection = "homepageSection";
  const track = "https://open.spotify.com/track";
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState<string>("");
  const parts = searchInput.split("/");
  const lastPart = parts[parts.length - 1];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [trackId, queryString] = lastPart.split("?");
  const { refetch } = useGetArtistListQuery<any>(
    { trackId: trackId },

    {
      skip: !searchInput.includes(track),
    }
  );
  const handleConnect = async () => {
    // window.location.href = `http://localhost:9090/api/auth/login?trackId=${trackId}`;
    window.location.href = `https://api.pitchplaylists.com/api/auth/login?trackId=${trackId}`;
  };
  const handleSearchChange = (value: React.SetStateAction<string>) => {
    setSearchInput(value);
  };
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const isLoggedIn = token && userId;
  useEffect(() => {
    if (searchInput.includes(track)) {
      if (isLoggedIn) {
        refetch({ trackId: trackId }).then((response: any) => {
          if (response.status === "fulfilled") {
            navigate(ROUTES.SUBMIT_A_SONG_1, {
              state: { trackData: response.data },
            });
          } else {
            console.error("Unexpected status:", response.status);
            navigate(ROUTES.PAGE_NOT_FOUND);
          }
        });
      } else {
        handleConnect();
      }
    } else {
      setTimeout(() => {
        setSearchInput("");
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, searchInput]);
  const isInvalid = searchInput !== "" && !searchInput.includes(track);
  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{
          padding: "35px",
          paddingRight: "10px",
          paddingTop: "0px",
          marginTop: "75px !important",
          justifyContent: isSmallScreen ? "center" : "space-between",
        }}
      >
        <Grid
          item
          xs={isMediumScreen ? 3.5 : 3}
          sx={{
            padding: "0!important",
            display: isSmallScreen ? "none" : "block",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: isMediumScreen ? "12px" : "42px",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                flexDirection: "column",
                gap: isMediumScreen ? "12px" : "42px",
              }}
            >
              <img src={Artist1} alt="" />
              <img src={Artist2} alt="" />
            </Box>
            <Box>
              <img src={Artist3} alt="" />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={xxs ? 12 : isSmallScreen ? 10 : 4.9}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: isExtraSmallScreen ? "30px !important" : "0 !important",
            gap: isMediumScreen ? "20px" : "30px",
          }}
        >
          <Chip
            label="Simple tool for curators to manage playlists and for artists to submit music"
            sx={{
              color: "#FFFFFF",
              fontSize: xxs ? "14px" : "18px",
              fontFamily: themeFonts["Poppins-Medium"],
              background: themeColors["#00000066"],
              minHeight: "35px",
              paddingY: "6px",
              paddingX: "8px",
              whiteSpace: "normal",
              height: "fit-content",
              textAlign: "center",
              borderRadius: "50px",
              "& span.MuiChip-label": {
                whiteSpace: "normal",
              },
            }}
          />
          <Box>
            <Typography
              sx={{
                color: "#FFFFFF",
                fontSize: isMiniScreen ? "20px" : "36px",
                fontFamily: themeFonts["Poppins-Bold"],
                textAlign: "center",
              }}
            >
              Submit your music to curators instantly, for free! Focus on
              creating music and send your songs to the right playlists
            </Typography>
          </Box>
          <Box sx={{ width: xxs ? "100%" : "61.7%" }}>
            <SearchComponents
              searchTitle={"Insert your Spotify track link"}
              isEmpty={true}
              homepageSection={homepageSection}
              onSearchChange={handleSearchChange}
              value={searchInput}
              isInvalid={isInvalid}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={isMediumScreen ? 3.5 : 3}
          sx={{
            padding: "0!important",
            display: isSmallScreen ? "none" : "block",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: isMediumScreen ? "12px" : "42px",
              justifyContent: "center",
            }}
          >
            <Box>
              <img src={Artist6} alt="" />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                flexDirection: "column",
                gap: isMediumScreen ? "12px" : "42px",
              }}
            >
              <img src={Artist4} alt="" />
              <img src={Artist5} alt="" />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
