import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ROUTES } from "../components/consts/routes.consts";
import { ToastContainer } from "react-toastify";
import { Home } from "../pages/home/home";
// import FAQPage from "../pages/faq-page";
import "react-toastify/dist/ReactToastify.css";

import { AuthLayout } from "../components/layouts/auth-layout/auth.layout";
import { UnauthLayout } from "../components/layouts/unauth-layout/unauth.layouts";
// import TopChart from "../pages/home/top-curator-chart";
import { Dashboard } from "../pages/dashboard/dashboard";
import { Callback } from "../pages/callback";
import { SubmitSongPage } from "../pages/submit-pages/submit-a-song";
import { SubmitPlaylistPage } from "../pages/submit-pages/submit-a-playlist";
import TermsConditions from "../pages/home/privacy-terms/privacy-terms";
import { DashboardLayout } from "../pages/dashboard/dashboard-layout";
import { ConfirmPlaylistPage } from "../pages/submit-pages/confirm-playlist";
import { SubmitASong } from "../pages/submit-pages/submit-a-song-1";
import { SelectingGenres } from "../pages/selecting-genres";
import VisitPublicPage from "../pages/dashboard/visit-public-page";
import { ReferralPage } from "../pages/referral-page";
import PageNotFound from "../pages/submit-pages/page-not-found";
import { Accounts } from "../pages/profile/accounts";
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
export const RootNavigator = () => {
  const query = useQuery();
  const accessToken: any = query.get("access_token");
  const refreshToken: any = query.get("refresh_token");
  const curatorName: any = query.get("curator");
  const trackId: any = query.get("trackId");
  const referrerId: any = query.get("referrerId");
  const playlistId: any = query.get("playlistId");
  if (accessToken) {
    window.localStorage.setItem("token", accessToken);
  }
  if (refreshToken) {
    window.localStorage.setItem("refresh_token", refreshToken);
  }
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const isLoggedIn = token && userId;

  return (
    <>
      <Routes>
        <Route path={ROUTES.REFERRAL_PAGE} element={<ReferralPage />} />
        <Route element={isLoggedIn ? <AuthLayout /> : <UnauthLayout />}>
          <Route
            path={ROUTES.HOME}
            element={
              !isLoggedIn ? (
                <Home />
              ) : (
                <Navigate to={ROUTES.DASHBOARD} replace={true} />
              )
            }
          />
          {/* <Route path={ROUTES.FAQ} element={<FAQPage />} /> */}
          {/* <Route path={ROUTES.TOP_CURATOR_CHART} element={<TopChart />} /> */}

          <Route
            path={ROUTES.VISIT_PUBLIC_PAGE}
            element={<VisitPublicPage />}
          />
          <Route
            path={ROUTES.ACCOUNT}
            element={
              isLoggedIn ? (
                <Accounts />
              ) : (
                <Navigate to={ROUTES.HOME} replace={true} />
              )
            }
          />
          <Route element={<DashboardLayout />}>
            <Route
              path={ROUTES.DASHBOARD}
              element={
                isLoggedIn ? (
                  <Dashboard />
                ) : (
                  <Navigate to={ROUTES.HOME} replace={true} />
                )
              }
            />
          </Route>
          <Route path={ROUTES.SUBMIT_A_SONG} element={<SubmitSongPage />} />
          <Route
            path={ROUTES.SUBMIT_A_SONG_1}
            element={
              isLoggedIn ? (
                <SubmitASong />
              ) : (
                <Navigate to={ROUTES.HOME} replace={true} />
              )
            }
          />
          <Route
            path={ROUTES.SUBMIT_A_PLAYLIST}
            element={<SubmitPlaylistPage />}
          />
          <Route
            path={ROUTES.SUBMIT_A_SONG_PLAYLIST}
            element={
              isLoggedIn ? (
                <SelectingGenres />
              ) : (
                <Navigate to={ROUTES.HOME} replace={true} />
              )
            }
          />
          <Route
            path={ROUTES.CONFIRM_PLAYLIST}
            element={
              isLoggedIn ? (
                <ConfirmPlaylistPage />
              ) : (
                <Navigate to={ROUTES.HOME} replace={true} />
              )
            }
          />
          <Route
            path={ROUTES.TERMS_AND_CONDITIONS}
            element={<TermsConditions />}
          />
        </Route>
        <Route
          path={ROUTES.CALLBACK}
          element={
            token ? (
              <Callback
                trackId={trackId}
                playlistId={playlistId}
                curatorName={curatorName}
                referrerId={referrerId}
              />
            ) : (
              <Navigate to={ROUTES.HOME} replace={true} />
            )
          }
        />
        <Route path={ROUTES.PAGE_NOT_FOUND} element={<PageNotFound />} />
      </Routes>
      <ToastContainer position="bottom-right" theme="colored" />
    </>
  );
};
