import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  Link as MuiLink,
} from "@mui/material";
import { AddSongBackground, SpotifyGreenLogo } from "../../pngs";
import Button from "@mui/material/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { ROUTES } from "../../components/consts/routes.consts";
import { themeColors, themeFonts } from "../../configs";
export const SubmitASong = () => {
  const isMiniScreen = useMediaQuery("(max-width:500px)");
  const location = useLocation();
  const { trackData, curatorName } = location.state || {};
  const navigate = useNavigate();
  const handleContinue = () => {
    navigate(ROUTES.SUBMIT_A_SONG_PLAYLIST, {
      state: { trackData: trackData.id, curatorName },
    });
  };

  return (
    <Grid
      sx={{
        background: `url(${AddSongBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",
        marginTop: "-80px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            maxWidth: "600px",
            width: "100%",
            textAlign: "center",
            padding: "0px 15px",
          }}
        >
          <Typography
            sx={{
              fontSize: isMiniScreen ? "28px" : "36px",
              fontWeight: "600",
              color: "#ffffff",
            }}
          >
            Your Song Info
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              color: "#ffffff",
              margin: "20px 0px",
            }}
          >
            Is this the track you want to submit?
          </Typography>

          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              boxShadow: "4px 4px 4px 0px #0000001A",
              padding: "15px",
            }}
          >
            <Box
              sx={{
                // margin: "auto",
                height: "54px",
                width: "117px",
                // display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <Link
                to={"https://open.spotify.com/"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={SpotifyGreenLogo}
                  alt=""
                  style={{
                    width: "90px",
                    height: "27px",
                  }}
                />
              </Link>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  // alignItems: "center",
                  flex: 1,
                  // justifyContent: "space-between",
                }}
              >
                <Box>
                  <Link
                    to={trackData?.external_urls?.spotify}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      width={"90px"}
                      src={trackData?.album?.images[1].url}
                      alt=""
                    />
                  </Link>
                </Box>
                <Box sx={{ padding: "0 10px 0px 10px", textAlign: "left" }}>
                  <MuiLink
                    component={Link}
                    to={trackData?.external_urls?.spotify}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      fontSize: "16px",
                      color: "#000000",
                      fontFamily: themeFonts["Poppins-Medium"],
                      display: "-webkit-box",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      wordWrap: "break-word",
                      textDecoration: "none",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    {trackData?.name}
                  </MuiLink>

                  {trackData?.artists.map((artist: any, index: any) => (
                    <MuiLink
                      key={index}
                      component={Link}
                      to={artist?.external_urls?.spotify}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        fontSize: "13px",
                        color: "#9f9f9f",
                        fontFamily: themeFonts["Poppins-Medium"],
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        margin: "0 2px 0 0px",
                        wordWrap: "break-word",
                        textDecoration: "none",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      {artist.name}
                      {index < trackData?.artists.length - 1 && ", "}
                    </MuiLink>
                  ))}
                </Box>
              </Box>

              <Box
                sx={{
                  flex: 1,
                }}
              >
                <iframe
                  src={`https://open.spotify.com/embed/track/${trackData.id}`}
                  width="100%"
                  height="90"
                  frameBorder={0}
                  allowTransparency={true}
                  title="gj"
                ></iframe>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{ display: "flex", alignItems: "center", marginTop: "16px" }}
          >
            <MuiLink
              component={Link}
              to={ROUTES.SUBMIT_A_SONG}
              sx={{
                color: themeColors["#FFFFFF"],
                fontSize: "16px",
                fontFamily: themeFonts["Poppins-Regular"],
              }}
            >
              Cancel
            </MuiLink>
            <Button
              onClick={handleContinue}
              variant="outlined"
              sx={{
                color: themeColors["#00ADB5"],
                fontFamily: themeFonts["Poppins-Regular"],
                borderColor: themeColors["#FFFFFF"],
                backgroundColor: themeColors["#FFFFFF"],
                height: "auto",
                marginLeft: "auto",
                padding: "8px 9px",
                display: "flex",
                gap: "4px",
                alignItems: "center",
                fontSize: "13px",
                "&:hover": {
                  background: themeColors["#FFFFFF"],
                  border: "1px solid #FFFFFF",
                },
              }}
            >
              <ThumbUpIcon />
              That's right, continue
            </Button>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};
