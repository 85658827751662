import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (builder) => ({
    saveTrack: builder.mutation<any, UserApiArg>({
      query: (queryArg) => {
        return {
          url: `/api/track/add-or-remove`,
          method: "POST",
          body: queryArg,
        };
      },
    }),
    // userUnFollow: builder.mutation<any, UserApiArg>({
    //   query: (queryArg) => {
    //     return {
    //       url: `/api/follow-user/un-follow`,
    //       method: "POST",
    //       body: queryArg,
    //     };
    //   },
    // }),
  }),
});
export { injectedRtkApi as enhancedApi };
export type UserApiArg = {
  trackId?: any;
  action?: any;
};
export const {
  useSaveTrackMutation,
  // useUserUnFollowMutation
} = injectedRtkApi;
