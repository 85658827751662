import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { themeFonts, themeColors } from "../../configs";
import { ROUTES } from "../consts/routes.consts";
import { useNavigate } from "react-router-dom";
export const ResponsiveNavbarDialog = ({
  handleClose,
  pathname,
  handleSubmitMenuClick,
  auth,
}: {
  handleClose: any;
  pathname: any;
  handleSubmitMenuClick: any;
  auth: any;
}) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    window.localStorage.removeItem("id");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("userId");
    window.localStorage.removeItem("userName");
    window.localStorage.removeItem("userEmail");
    window.localStorage.removeItem("userCredits");
    window.localStorage.removeItem("refresh_token");
    navigate(ROUTES.HOME);
    handleClose();
  };

  return (
    <Box
      sx={{
        width: "100%",
        zIndex: "100",
        height: auth ? "calc(-65px + 100vh)" : "calc(-144px + 100vh)",
        display: "block",
        overflow: "scroll",
        position: "fixed",
        top: auth ? "75px" : "145px",
        left: "0",
        background: "aliceblue",
      }}
    >
      <List>
        {/* <ListItem disablePadding>
          <ListItemButton
            sx={{
              color: themeColors["#000000"],
              textAlign: "center",
            }}
            selected={pathname === ROUTES.FAQ}
            onClick={() => {
              navigate(ROUTES.FAQ);
              handleClose();
            }}
          >
            <ListItemText
              primary="FAQ"
              sx={{
                fontSize: "22px",
                fontFamily: themeFonts["Poppins-SemiBold"],
              }}
            />
          </ListItemButton>
        </ListItem> */}

        <ListItem disablePadding>
          <ListItemButton
            sx={{
              color: themeColors["#000000"],
              textAlign: "center",
            }}
            selected={pathname === ROUTES.SUBMIT_A_SONG}
            onClick={() => {
              navigate(ROUTES.SUBMIT_A_SONG);
              handleClose();
            }}
          >
            <ListItemText
              primary="Submit a Song"
              sx={{
                fontSize: "22px",
                fontFamily: themeFonts["Poppins-SemiBold"],
              }}
            />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            sx={{
              color: themeColors["#000000"],
              textAlign: "center",
            }}
            selected={pathname === ROUTES.SUBMIT_A_PLAYLIST}
            onClick={() => {
              navigate(ROUTES.SUBMIT_A_PLAYLIST);
              handleClose();
            }}
          >
            <ListItemText
              primary="Submit a Playlist"
              sx={{
                fontSize: "22px",
                fontFamily: themeFonts["Poppins-SemiBold"],
              }}
            />
          </ListItemButton>
        </ListItem>

        {/* <ListItem disablePadding>
          <ListItemButton
            sx={{
              color: themeColors["#000000"],
              textAlign: "center",
            }}
            selected={pathname === ROUTES.TOP_CURATOR_CHART}
            onClick={() => {
              navigate(ROUTES.TOP_CURATOR_CHART);
              handleClose();
            }}
          >
            <ListItemText
              primary="Top Curator Chart"
              sx={{
                fontSize: "22px",
                fontFamily: themeFonts["Poppins-SemiBold"],
              }}
            />
          </ListItemButton>
          
        </ListItem> */}

        {auth && (
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                color: themeColors["#000000"],
                textAlign: "center",
              }}
              onClick={() => {
                handleLogout();
              }}
            >
              <ListItemText
                primary="Logout"
                sx={{
                  fontSize: "22px",
                  fontFamily: themeFonts["Poppins-SemiBold"],
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );
};
