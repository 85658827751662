import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  Typography,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { themeColors, themeFonts } from "../../configs";

export const PreferenceModal = ({ Transition, open, handleClose }: any) => {
  const CookiesAccepted = localStorage.getItem("cookies_accepted");
  const [checked, setChecked] = React.useState(false); // Single checkbox state for Strictly Necessary Cookies

  useEffect(() => {
    // Load state from localStorage when the component mounts
    const savedChecked = JSON.parse(
      localStorage.getItem("strictly_necessary_checked") || "false"
    );
    setChecked(savedChecked);
  }, []);

  useEffect(() => {
    // Automatically check the box if cookies are accepted
    if (CookiesAccepted === "Accepted" && open) {
      setChecked(true);
    }
  }, [CookiesAccepted, open]);

  const handleToggle = () => {
    setChecked((prevChecked) => !prevChecked);
  };

  const handleSave = () => {
    localStorage.setItem("cookies_accepted", checked ? "Accepted" : "Custom");
    localStorage.setItem("strictly_necessary_checked", JSON.stringify(checked));
    handleClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 15,
          top: 10,
          height: 24,
          width: 24,
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <DialogTitle sx={{ paddingTop: "30px" }}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "600",
            color: "#000000",
          }}
        >
          Preferences
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          sx={{
            fontSize: "12px",
            fontFamily: themeFonts["Poppins-Regular"],
            color: themeColors["#00000080"],
          }}
        >
          We use a single cookie to optimize your experience on our website. You
          can manage your cookie preference here and update it anytime.
          Disabling the cookie may affect your website experience. Learn more in
          our{" "}
          <a
            href="https://pitchplaylists.com/terms-and-conditions"
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            Cookie Policy
          </a>
        </DialogContentText>
        <List
          sx={{ width: "100%", bgcolor: "background.paper", marginTop: "30px" }}
        >
          <ListItem
            disablePadding
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: "15px",
              borderBottom: "1px solid #00000040",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                flexDirection: "row",
                gap: "5px",
              }}
            >
              <ListItemButton
                role={undefined}
                onClick={handleToggle}
                dense
                sx={{ padding: "10px", height: "30px", width: "30px" }}
              >
                <ListItemIcon
                  sx={{
                    height: "20px",
                    width: "20px",
                    minWidth: "20px",
                    "& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root":
                      { height: "16px", width: "16px" },
                  }}
                >
                  <Checkbox
                    edge="start"
                    checked={checked}
                    tabIndex={-1}
                    disableRipple
                    // inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
              </ListItemButton>
              <ListItemText
                primary={"Strictly Necessary Cookies"}
                sx={{
                  "& span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary":
                    {
                      fontSize: "14px",
                      fontFamily: themeFonts["Poppins-SemiBold"],
                    },
                }}
              />
            </Box>
            <ListItemText
              sx={{
                marginLeft: "38px",
                "& span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary":
                  {
                    fontSize: "12px",
                    fontFamily: themeFonts["Poppins-Regular"],
                  },
              }}
            >
              Strictly necessary cookies are essential for the basic functioning
              of a website. They are used to ensure that a site works properly
              and allow users to navigate and use its features. These cookies do
              not collect personal data and are not used for tracking or
              analytics.
            </ListItemText>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClose}>Allow All</Button>
        <Button onClick={handleClose}>Decline All</Button> */}
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};
