import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const ReferralPage = () => {
  const { id } = useParams();
  useEffect(() => {
    // window.location.href = `http://localhost:9090/api/auth/login?referrerId=${id}`;
    window.location.href = `https://api.pitchplaylists.com/api/auth/login?referrerId=${id}`;
  }, [id]);
  return null;
};
