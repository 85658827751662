import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  Box,
  Button,
  Dialog,
  IconButton,
  Typography,
  Link as MuiLink,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import {
  // ArrowDownIcon,
  CloseIconSvg,
  FilledDollarIcon,
  // SubmissionGateIcon,
} from "../../svgs";
import { themeColors, themeFonts } from "../../configs";

import { useGetUsersListQuery } from "../apis/userDetailsAPi";
import { Link } from "react-router-dom";
import { SpotifyGreenLogo } from "../../pngs";
export const SongSubmissionDialog = (props: any) => {
  const isMiniScreen = useMediaQuery("(max-width:500px)");
  // const isSmallScreen = useMediaQuery("(max-width:700px)");

  // const [expanded, setExpanded] = useState<string | false>(false);
  // const [nestedExpanded, setNestedExpanded] = useState<string | false>(false);

  // const handleExpansion =
  //   (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
  //     setExpanded(isExpanded ? panel : false);
  //   };

  // const handleNestedExpansion =
  //   (panel: any) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
  //     setNestedExpanded(isExpanded ? panel : false);
  //   };
  const { data: creditsData } = useGetUsersListQuery<any>();
  const { onClose, open, handleSubmit, selectedPlaylists, isLoading } = props;
  const handleClose = () => {
    onClose();
  };

  // const hasGates = selectedPlaylists?.some(
  //   (playlistItem: {
  //     saveSong: any;
  //     followAccount: any;
  //     followPlaylist: any;
  //   }) =>
  //     playlistItem.saveSong ||
  //     playlistItem.followAccount ||
  //     playlistItem.followPlaylist
  // );

  return (
    <Box>
      <Box sx={{}}>
        <Dialog
          onClose={handleClose}
          open={open}
          sx={{
            "& .MuiPaper-root": {
              maxWidth: "718px",
              width: "718px",
              borderRadius: "0px",
              padding: "24px",
            },
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 20,
              top: 15,
            }}
          >
            <CloseIconSvg />
          </IconButton>
          <Box
            sx={{
              marginTop: "30px",
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                fontFamily: themeFonts["Poppins-SemiBold"],
                color: themeColors["#000000"],
                textAlign: "center",
              }}
            >
              Song Submission Ready
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: themeFonts["Poppins-Regular"],
                color: themeColors["#00000080"],
                textAlign: "center",
              }}
            >
              Submissions do not guarantee feedback from the curators.
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: "16px",
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "center",
              background: themeColors["#D9D9D980"],
              padding: "16px",
              gap: "9px",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: themeFonts["Poppins-SemiBold"],
                color: themeColors["#2CA9BC"],
              }}
            >
              Your Balance
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: themeFonts["Poppins-SemiBold"],
                color: themeColors["#000000"],
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "8px",
              }}
            >
              <FilledDollarIcon /> {creditsData?.user?.userCredits} credits
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: "16px",
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: themeFonts["Poppins-Regular"],
                color: themeColors["#00000080"],
              }}
            >
              You need {selectedPlaylists?.length} credits for this submission
            </Typography>
          </Box>
          <Box
            sx={{
              marginY: "16px",
              display: "flex",
              flexDirection: "column",
              paddingTop: "16px",
              gap: "10px",
              maxHeight: "168px",
              overflow: "auto",
              paddingRight: "10px",
            }}
          >
            {selectedPlaylists?.map(
              (playlistItem: any, index: React.Key | null | undefined) => (
                <Box
                  sx={{
                    borderBottom: "1px solid #00000040",
                    paddingBottom: "10px",
                  }}
                >
                  <Box
                    sx={{
                      height: "42px",
                      width: "91px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Link
                      to={"https://open.spotify.com/"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={SpotifyGreenLogo}
                        alt=""
                        style={{
                          width: "70px",
                          height: "21px",
                        }}
                      />
                    </Link>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "space-between",
                      paddingLeft: "10.5px",
                    }}
                    key={index}
                  >
                    <MuiLink
                      component={Link}
                      to={playlistItem.playlistUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        fontSize: "16px",
                        fontFamily: themeFonts["Poppins-SemiBold"],
                        color: themeColors["#000000"],
                        textTransform: "capitalize",
                        textDecoration: "none",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      {playlistItem.playlistName}
                    </MuiLink>

                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: themeFonts["Poppins-Regular"],
                        color: themeColors["#00000080"],
                        marginLeft: "12px",
                      }}
                    >
                      ( 1 Credit )
                    </Typography>
                  </Box>
                </Box>
              )
            )}
          </Box>

          {/* <Box sx={{}}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleExpansion("panel1")}
              sx={{
                "&.MuiPaper-root.MuiAccordion-root": {
                  background: "#F0F0F0",
                  // border: "1px solid #00ADB5CC",
                  boxShadow: "none",
                  borderRadius: "5px",
                  margin: "0px",
                  padding: "0px",
                  width: "100%",
                  marginBottom: "20px",
                  height: "auto",
                  "& .MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered":
                    {
                      height: "auto !important",
                    },
                },
              }}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? (
                    <SubmissionGateIcon />
                  ) : (
                    <ArrowDownIcon />
                  )
                }
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{
                  height: "auto",
                  "&.MuiAccordionSummary-gutters .Mui-expanded": {
                    margin: "0px !important",
                  },
                  "&.Mui-expanded": {
                    minHeight: "45px !important",
                  },
                }}
              >
                <Typography
                  sx={{
                    padding: "0px",
                    fontFamily: themeFonts["Poppins-Medium"],
                    fontSize: "14px",
                    color: expanded === "panel1" ? "#00ADB5" : "#000000",
                    "&.Mui-expanded": {
                      margin: "0px !important",
                    },
                  }}
                >
                  Playlist submission gate
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "0px", height: "auto" }}>
                <Box
                  sx={{
                    border: "0.5px solid #00000011",
                    boxShadow: "10px 10px 20px 0px #0000001A",
                    maxHeight: "220px",
                    overflowY: "scroll",
                    backgroundColor: "#FFFFFF",
                    "&::-webkit-scrollbar": {
                      width: "5px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#D9D9D9",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: "#D9D9D9",
                    },
                  }}
                >
                  {hasGates ? (
                    <>
                      {selectedPlaylists?.map(
                        (
                          playlistItem: any,
                          index: React.Key | null | undefined
                        ) => {
                          const notGates =
                            !playlistItem.saveSong &&
                            !playlistItem.followAccount &&
                            !playlistItem.followPlaylist;
                          return (
                            <>
                              {!notGates && (
                                <Accordion
                                  expanded={nestedExpanded === index}
                                  onChange={handleNestedExpansion(index)}
                                  sx={{
                                    "&.MuiPaper-root.MuiAccordion-root": {
                                      boxShadow: "none",
                                      margin: "0px",
                                      padding: "0px",
                                      width: "100%",
                                      height: "auto",
                                      "& .MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered":
                                        {
                                          height: "auto !important",
                                        },
                                      "&.MuiPaper-root.MuiAccordion-root::before":
                                        {
                                          margin: "0px 16px !important",
                                        },
                                    },
                                  }}
                                >
                                  <AccordionSummary
                                    expandIcon={<ArrowDownIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                    sx={{
                                      height: "auto",
                                      "&.MuiAccordionSummary-root": {
                                        borderBottom:
                                          "0.3px solid #00000020 !important",
                                        margin: "0px 16px",
                                        padding: "0px",
                                      },
                                    }}
                                  >
                                    <Box>
                                      <Box
                                        sx={{
                                          height: "42px",
                                          width: "91px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      ><Link
                        to={"https://open.spotify.com/"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                                        <img
                                          src={SpotifyGreenLogo}
                                          alt=""
                                          style={{
                                            width: "70px",
                                            height: "21px",
                                          }}
                                        /></Link>
                                      </Box>
                                      <MuiLink
                                        component={Link}
                                        to={playlistItem.playlistUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                          fontSize: "16px",
                                          fontFamily:
                                            themeFonts["Poppins-SemiBold"],
                                          color: themeColors["#000000"],
                                          textTransform: "capitalize",
                                          textDecoration: "none",
                                          paddingLeft: "10.5px",
                                          "&:hover": {
                                            textDecoration: "underline",
                                          },
                                        }}
                                      >
                                        {playlistItem.playlistName}
                                      </MuiLink>
                                    </Box>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    sx={{
                                      height: "auto",
                                      "&.MuiAccordionDetails-root": {
                                        padding: isSmallScreen
                                          ? "9px 16px 16px !important"
                                          : "9px 0px 16px !important",
                                        borderBottom:
                                          "0.3px solid #00000020 !important",
                                        width: isSmallScreen ? "auto" : "630px",
                                        margin: "auto",
                                      },
                                    }}
                                  >
                                    {playlistItem.followAccount && (
                                      <Box>
                                        <Box
                                          sx={{
                                            height: "42px",
                                            width: "91px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                           <Link
                              to={"https://open.spotify.com/"}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={SpotifyGreenLogo}
                                alt=""
                                style={{ width: "70px", height: "21px" }}
                              />
                            </Link>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            marginBottom: "17px",
                                            // flexDirection: isSmallScreen ? "column":"row"
                                            paddingLeft: "10.5px",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              color: "#00000080",
                                              fontFamily:
                                                themeFonts["Poppins-Regular"],
                                              // width: isSmallScreen ? "100%":" 40%",
                                              width: "40%",
                                              fontSize: "16px",
                                            }}
                                          >
                                            {isSmallScreen
                                              ? "Follow account"
                                              : "Follow account on Spotify"}
                                          </Typography>
                                          <MuiLink
                                            component={Link}
                                            to={
                                              playlistItem.submitConditions
                                                .followUserUrls
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{
                                              color: "#00ADB5",
                                              fontFamily:
                                                themeFonts["Poppins-SemiBold"],
                                              // width: isSmallScreen ? "100%":" 60%",
                                              width: "60%",
                                              fontSize: "16px",
                                              cursor: "pointer",
                                              textDecoration: "none",
                                              "&:hover": {
                                                textDecoration: "none",
                                              },
                                            }}
                                          >
                                            {playlistItem.followAccount}
                                          </MuiLink>
                                        </Box>
                                      </Box>
                                    )}
                                    {playlistItem.followPlaylist && (
                                      <Box>
                                        <Box
                                          sx={{
                                            height: "42px",
                                            width: "91px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                            <Link
                              to={"https://open.spotify.com/"}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={SpotifyGreenLogo}
                                alt=""
                                style={{ width: "70px", height: "21px" }}
                              />
                            </Link>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            marginBottom: "17px",
                                            //  flexDirection: isSmallScreen ? "column":"row"
                                            paddingLeft: "10.5px",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              color: "#00000080",
                                              fontFamily:
                                                themeFonts["Poppins-Regular"],
                                              // width: isSmallScreen ? "100%":" 40%",
                                              width: "40%",
                                              fontSize: "16px",
                                            }}
                                          >
                                            {isSmallScreen
                                              ? "Follow Playlist"
                                              : "Follow Playlist on Spotify"}
                                          </Typography>
                                          <MuiLink
                                            component={Link}
                                            to={
                                              playlistItem.submitConditions
                                                .followPlaylistUrls
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{
                                              color: "#00ADB5",
                                              fontFamily:
                                                themeFonts["Poppins-SemiBold"],
                                              width: "60%",
                                              fontSize: "16px",
                                              cursor: "pointer",
                                              textDecoration: "none",
                                              "&:hover": {
                                                textDecoration: "none",
                                              },
                                            }}
                                          >
                                            {playlistItem.followPlaylist}
                                          </MuiLink>
                                        </Box>
                                      </Box>
                                    )}
                                    {playlistItem.saveSong && (
                                      <Box>
                                        <Box
                                          sx={{
                                            height: "42px",
                                            width: "91px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                            <Link
                              to={"https://open.spotify.com/"}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={SpotifyGreenLogo}
                                alt=""
                                style={{ width: "70px", height: "21px" }}
                              />
                            </Link>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            paddingLeft: "10.5px",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              color: "#00000080",
                                              fontFamily:
                                                themeFonts["Poppins-Regular"],
                                              // width: isSmallScreen ? "100%":" 40%",
                                              width: "40%",
                                              fontSize: "16px",
                                            }}
                                          >
                                            {isSmallScreen
                                              ? " Save song"
                                              : " Save song"}
                                          </Typography>
                                          <MuiLink
                                            component={Link}
                                            to={
                                              playlistItem.submitConditions
                                                .saveTrackUrl
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{
                                              color: "#00ADB5",
                                              fontFamily:
                                                themeFonts["Poppins-SemiBold"],
                                              // width: isSmallScreen ? "100%":" 60%",
                                              width: "60%",
                                              fontSize: "16px",
                                              cursor: "pointer",
                                              textDecoration: "none",
                                              "&:hover": {
                                                textDecoration: "none",
                                              },
                                            }}
                                          >
                                            {playlistItem.saveSong}
                                          </MuiLink>
                                        </Box>
                                      </Box>
                                    )}
                                  </AccordionDetails>
                                </Accordion>
                              )}
                            </>
                          );
                        }
                      )}
                    </>
                  ) : (
                    <Typography
                      sx={{
                        fontFamily: themeFonts["Poppins-Regular"],
                        color: themeColors["#00000080"],
                        textAlign: "center",
                        fontSize: "14px",
                        padding: "16px",
                      }}
                    >
                      No additional actions are required before selected
                      curators and their playlists receive your song
                    </Typography>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box> */}

          <Box sx={{ textAlign: "center" }}>
            <Button
              onClick={handleSubmit}
              className="margin-0"
              variant="contained"
              sx={{
                marginLeft: "auto",
                padding: "11px 20px",
                backgroundColor: themeColors["#00ADB5"],
                color: themeColors["#FFFFFF"],
                borderRadius: "33px",
                height: "auto",
                fontSize: isMiniScreen ? "14px" : "18px",
                fontFamily: themeFonts["Poppins-Bold"],
                minWidth: "150px",
              }}
              disabled={creditsData?.user?.userCredits === 0}
            >
              {isLoading ? (
                <CircularProgress
                  size={17}
                  sx={{
                    color: "inherit",
                    fontWeight: "900",
                  }}
                />
              ) : (
                "Continue and submit"
              )}
            </Button>
          </Box>
        </Dialog>
      </Box>
    </Box>
  );
};
