import { themeColors, themeFonts } from "../../configs";
import { Box, InputBase } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { SpotifyBlackIcon } from "../../pngs";

interface SearchFilledInputProps {
  size?: "medium" | "large" | "small";
  variant?: "filled" | "outlined";
  placeholder?: string;
  height?: string;
  width?: string;
  onChange?: any;
  showIcon?: boolean;
  isInvalid?: any;
  value?: any;
}

export const SearchFilledInput = ({
  size = "medium",
  variant = "filled",
  placeholder = "Search",
  height,
  width,
  onChange,
  showIcon = true,
  isInvalid,
  value,
}: SearchFilledInputProps) => {
  const location = useLocation();
  const { ids } = useParams();
  const containerStyle = {
    backgroundColor:
      variant === "filled" ? themeColors["#FFFFFF"] : "transparent",
    borderRadius: size === "medium" ? "32px" : "32px",
    border: isInvalid
      ? "1px solid red"
      : variant === "filled"
      ? "none"
      : `1px solid ${themeColors["#E9EEF2"]}`,
    height: size === "medium" ? 48 : height ? { height } : 48,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // paddingLeft: size === "medium" ? 2 : 1.5,
    boxShadow: "10px 10px 10px #0000000D",
    "& svg path": {
      fill: isInvalid ? "red" : "",
    },
  };

  const inputStyle = {
    paddingRight: size === "medium" ? "10px" : 1.3,
    fontFamily: themeFonts["Poppins-Regular"],
    fontSize: size === "medium" ? 14 : 14,
    width: "100%",
    textDecoration: "underline",
    color: isInvalid ? "red" : themeColors["#00000066"],
    "& input::placeholder": {
      opacity: 1,
    },
  };

  const renderIcon = () => {
    if (location.pathname === `/submit-public-page/${ids}`) {
      return (
        <Box
          sx={{
            height: "50px",
            width: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Link
            to={"https://open.spotify.com/"}
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: "25px", height: "25px" }}
          >
            <img
              src={SpotifyBlackIcon}
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
          </Link>
        </Box>
      );
    }
    return (
      <Box
        sx={{
          height: "50px",
          width: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Link
          to={"https://open.spotify.com/"}
          target="_blank"
          rel="noopener noreferrer"
          style={{ width: "25px", height: "25px" }}
        >
          <img
            src={SpotifyBlackIcon}
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </Link>
      </Box>
    );
  };

  return (
    <Box sx={containerStyle}>
      {showIcon && renderIcon()}
      <InputBase
        placeholder={placeholder}
        sx={inputStyle}
        onChange={onChange}
        value={value}
      />
    </Box>
  );
};
