import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (builder) => ({
    userFollow: builder.mutation<any, UserApiArg>({
      query: (queryArg) => {
        return {
          url: `/api/follow-user`,
          method: "POST",
          body: queryArg,
        };
      },
    }),
    userUnFollow: builder.mutation<any, UserApiArg>({
      query: (queryArg) => {
        return {
          url: `/api/follow-user/un-follow`,
          method: "POST",
          body: queryArg,
        };
      },
    }),
  }),
});
export { injectedRtkApi as enhancedApi };
export type UserApiArg = {
  userUrl?: any;
  id?: any;
};
export const { useUserFollowMutation, useUserUnFollowMutation } =
  injectedRtkApi;
