import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  ListItemButton as MuiListItemButton,
  listItemButtonClasses,
  ListItemButtonProps,
  ListItemIcon as MuiListItemIcon,
  listItemIconClasses,
  ListItemIconProps,
  ListItemText as MuiListItemText,
  listItemTextClasses,
  ListItemTextProps,
  Button,
  IconButton,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { NavbarMenuLeftLayout } from "./navbar-menu-left-layout";
import { themeColors, themeFonts } from "../../configs";
import { ResponsiveNavbarDialog } from "../modals/responsive-navbar";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { SubmitMenu } from "../dropdown/submit-menu";
import { ROUTES } from "../consts/routes.consts";
import { ProfileMenu } from "../dropdown/profile-menu";
// import { useGetUsersListQuery } from "../apis/userDetailsAPi";
import SelectMoreSubmissions from "../modals/want-more-submissions";
import { SpotifyWhiteIcon } from "../../pngs";

export const NavbarMenu = ({
  isSmallScreen,
  open,
  handleOpen,
  handleClose,
  handleConnect,
  auth,
  isDashboardPage,
  isSubmitPage,
}: {
  isSmallScreen?: any;
  open?: any;
  handleOpen?: any;
  handleClose?: any;
  handleConnect?: any;
  auth?: any;
  isDashboardPage?: any;
  isSubmitPage?: any;
}) => {
  // const UserProfileImage = localStorage.getItem("userProfileImage");
  const navigate = useNavigate();
  const location = useLocation();
  const styles = getStyles();
  const pathname = "/" + location.pathname.split("/")[1];
  const [submitMenuAnchorEl, setSubmitMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [hasOpen, setHasOpen] = useState(false);
  const isSubmitMenuOpen = Boolean(submitMenuAnchorEl);
  const isProfileMenuOpen = Boolean(profileMenuAnchorEl);
  // const handleOpenButton = () => {
  //   setHasOpen(true);
  // };
  const handleCloseButton = () => {
    setHasOpen(false);
  };
  const handleSubmitMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setSubmitMenuAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setSubmitMenuAnchorEl(null);
    setProfileMenuAnchorEl(null);
  };
  // const { data: creditsData, isLoading } = useGetUsersListQuery<any>();

  return (
    <>
      <NavbarMenuLeftLayout
        isDashboardPage={isDashboardPage}
        isSmallScreen={isSmallScreen}
      />
      {isSubmitMenuOpen && (
        <SubmitMenu
          handleClose={handleMenuClose}
          anchorEl={submitMenuAnchorEl}
          open={isSubmitMenuOpen}
        />
      )}
      {isProfileMenuOpen && (
        <ProfileMenu
          handleClose={handleMenuClose}
          anchorEl={profileMenuAnchorEl}
          open={isProfileMenuOpen}
          auth={auth}
        />
      )}
      {hasOpen && (
        <SelectMoreSubmissions
          hasOpen={hasOpen}
          handleCloseButton={handleCloseButton}
        />
      )}
      {isSmallScreen ? (
        <>
          {!open ? (
            <IconButton
              sx={{
                cursor: "pointer",
                width: "32px",
                height: "32px",
                minWidth: "32px",
                minHeight: "32px",
                verticalAlign: "middle",
                color: isDashboardPage
                  ? themeColors["#00ADB5"]
                  : themeColors["#FFFFFF"],
              }}
              onClick={handleOpen}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 20,
                top: 26,
                cursor: "pointer",
                width: "32px",
                height: "32px",
                minWidth: "32px",
                minHeight: "32px",
                verticalAlign: "middle",
                color: isDashboardPage
                  ? themeColors["#00ADB5"]
                  : themeColors["#FFFFFF"],
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {auth && (
            <ListItemButton
              selected={pathname === ROUTES.DASHBOARD}
              onClick={() => navigate(ROUTES.DASHBOARD)}
              isDashboardPage={isDashboardPage}
              isSubmitPage={isSubmitPage}
            >
              <ListItemText primary={"Dashboard"} isSubmitPage={isSubmitPage} />
            </ListItemButton>
          )}
          <ListItemButton
            selected={
              pathname === ROUTES.SUBMIT_A_PLAYLIST ||
              pathname === ROUTES.SUBMIT_A_SONG ||
              pathname === ROUTES.SUBMIT_A_SONG_1
            }
            onClick={handleSubmitMenuClick}
            isDashboardPage={isDashboardPage}
            isSubmitPage={isSubmitPage}
          >
            <ListItemText primary={"Submit"} isSubmitPage={isSubmitPage} />
          </ListItemButton>
          <Box sx={styles.root}>
            {/* <ListItems
              menu={navbarMenuConfig}
              navigate={navigate}
              pathname={pathname}
              isDashboardPage={isDashboardPage}
              isSubmitPage={isSubmitPage}
            /> */}
          </Box>

          {!auth && (
            <Button
              onClick={handleConnect}
              sx={{
                fontSize: "16px",
                display: "flex",
                fontFamily: themeFonts["Poppins-Medium"],
                borderRadius: "30px",
                background: "#1DB954",
                height: "45px",
                color: "#ffffff",
                padding: "10px 16px",
                "&:hover": {
                  background: "#1aa34a",
                },
                paddingLeft: "0px !important",
              }}
            >
              <Box
                sx={{
                  height: "46px",
                  width: "46px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={SpotifyWhiteIcon}
                  alt=""
                  style={{ width: "23px", height: "23px" }}
                />
              </Box>
              Connect with Spotify
            </Button>
          )}
          {auth && (
            <>
              {/* <Button
                variant="contained"
                startIcon={<DollarIcon3 />}
                sx={{
                  height: "45px",
                  background: isDashboardPage
                    ? themeColors["#000000"]
                    : themeColors["#FFFFFF"],
                  color: isDashboardPage
                    ? themeColors["#FFFFFF"]
                    : themeColors["#000000"],
                  borderRadius: "33px",
                  position: "relative",
                  padding: "12px 20px",
                  fontSize: "16px",
                  fontFamily: themeFonts["Poppins-Medium"],
                  marginRight: "16px",
                  "& svg path": {
                    fill: isDashboardPage
                      ? themeColors["#FFFFFF"]
                      : themeColors["#2CA9BC"],
                  },
                  "&:hover": {
                    background: "rgb(17 159 255 / 30%)",
                    color: themeColors["#000000"],
                  },
                }}
                onClick={handleOpenButton}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress
                    size={17}
                    sx={{
                      color: "inherit",
                      fontWeight: "900",
                      marginRight: "15px",
                    }}
                  />
                ) : (
                  <span style={{ marginRight: "13px" }}>
                    {creditsData?.user?.userCredits}
                  </span>
                )}
                <AddIcon
                  style={{
                    position: "absolute",
                    top: "4px",
                    right: "11px",
                  }}
                />
              </Button> */}
              <IconButton onClick={handleProfileMenuClick}>
                <Avatar
                  // src={UserProfileImage as string}
                  sx={{
                    color: themeColors["#00ADB5"],
                    background: isDashboardPage
                      ? themeColors["#00ADB526"]
                      : themeColors["#FFFFFF"],
                    height: "45px",
                    width: "45px",
                    "& svg": {
                      height: "30px",
                      width: "30px",
                    },
                  }}
                />
              </IconButton>
            </>
          )}
        </Box>
      )}
      {open && (
        <ResponsiveNavbarDialog
          handleClose={handleClose}
          pathname={pathname}
          handleSubmitMenuClick={handleSubmitMenuClick}
          auth={auth}
        />
      )}
    </>
  );
};

export const getStyles = () => {
  return {
    root: {
      paddingRight: 3,
    },
    title: {
      fontFamily: themeFonts["Poppins-Medium"],
      color: "rgb(255 255 255 / 60%)",
      fontSize: 16,
      marginBottom: "4px",
      marginTop: "18px",
    },
    margin: {
      marginTop: "auto",
    },
  };
};
interface CustomListItemButtonProps extends ListItemButtonProps {
  isDashboardPage?: boolean;
  isSubmitPage?: any;
}
interface CustomListItemTextProps extends ListItemTextProps {
  isSubmitPage?: any;
}
export const ListItemButton = styled(
  ({ isDashboardPage, isSubmitPage, ...props }: CustomListItemButtonProps) => (
    <MuiListItemButton {...props} />
  )
)((props) => ({
  [`&.${listItemButtonClasses.root}`]: {
    paddingLeft: 14,
    paddingRight: 12,
    color: props.isDashboardPage
      ? themeColors["#000000"]
      : themeColors["#FFFFFF"],
    maxHeight: "35px",
    borderRadius: "0px",
    ":hover": {
      background: `none !important`,
      // color: props.isSubmitPage
      //   ? themeColors["#FFFFFF"]
      //   : themeColors["#00ADB5"],
    },
  },
  [`&.${listItemButtonClasses.selected}`]: {
    background: `none !important`,
    color: props.isSubmitPage ? themeColors["#FFFFFF"] : themeColors["#00ADB5"],
    "& svg path": {
      stroke: themeColors["#FFFFFF"],
    },
    [`& .${listItemTextClasses.primary}`]: {
      borderBottom: props.isSubmitPage
        ? "2px solid #FFFFFF !important"
        : "2px solid #00ADB5 !important;",
    },
  },
}));

export const ListItemIcon = styled(({ ...props }: ListItemIconProps) => (
  <MuiListItemIcon {...props} />
))((props) => ({
  [`&.${listItemIconClasses.root}`]: {
    minWidth: 34,
  },
}));

export const ListItemText = styled(
  ({ isSubmitPage, ...props }: CustomListItemTextProps) => (
    <MuiListItemText {...props} />
  )
)((props) => ({
  [`&.${listItemTextClasses.root}`]: {
    [`& .${listItemTextClasses.primary}`]: {
      fontSize: 16,
      fontWeight: "400",
      borderBottom: "2px solid transparent",
      fontFamily: themeFonts["Poppins-Medium"],
      ":hover": {
        color: props.isSubmitPage
          ? themeColors["#FFFFFF"]
          : themeColors["#00ADB5"],
        background: `none !important`,
        borderBottom: props.isSubmitPage
          ? "2px solid #FFFFFF !important"
          : "2px solid #00ADB5 !important;",
      },
    },
  },
}));
