import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import PrivacySectionTable from "./privacy-section-table";

export const CookiesList = ({ isMediumScreen }: { isMediumScreen: any }) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: "32px",
          border: "0.5px solid #00000040",
          borderRadius: "16px 16px 0px 0px",
        }}
      >
        <Box
          sx={{
            paddingRight: "24px",
          }}
        >
          <Typography className="terms-about-text">
            A cookie is a small data file (a text file) that a website requests
            your browser to save on your device, storing information like your
            language preferences or login details during your visit. These
            cookies, known as first-party cookies, are set directly by us. We
            also use third-party cookies, which originate from domains other
            than the one you are currently visiting (in this case, Spotify), to
            improve functionality.
          </Typography>
          <Typography className="terms-about-text">
            In particular, we utilize strictly necessary cookies to serve the
            following objectives:
          </Typography>
          <Box>
            <Typography className="terms-sub-heading">
              1. Essential Cookies
            </Typography>
            <Typography className="terms-about-text">
              These cookies are indispensable for the proper operation of the
              website and cannot be disabled within our systems. Typically, they
              are activated in response to actions initiated by you,
              constituting requests for services such as configuring privacy
              preferences, signing in, or completing forms. Although you can
              configure your browser to obstruct or notify you regarding these
              cookies, specific sections of the site may become non-functional.
              Importantly, these cookies do not retain personally identifiable
              information.
            </Typography>
          </Box>
          <Box>
            <Typography className="terms-sub-heading">
              2. Functional Cookies:
            </Typography>
            <Typography className="terms-about-text">
              Functional cookies empower the website to deliver enriched
              functionality and personalized experiences. They may be
              established by us or third-party providers whose services we've
              integrated into our web pages. If you opt not to authorize these
              cookies, certain or all of these services may not operate at their
              optimum capacity.
            </Typography>

            <PrivacySectionTable />

            {/* <table>
              <tr>
                <th>Plugin/Service Name</th>
                <th>Purpose</th>
                <th>Data Collection</th>
                <th>Data Sharing</th>
              </tr>
              <tr>
                <td>AWS</td>
                <td>Cloud computing services</td>
                <td>$Limited, respecting privacy (e.g., user analytics)</td>
                <td>No public sharing</td>
              </tr>
              <tr>
                <td>Stripe</td>
                <td>Payment processing service</td>
                <td>$Limited to necessary payment information</td>
                <td>No public sharing</td>
              </tr>
              <tr>
                <td>Spotify</td>
                <td>Music streaming and playlist data</td>
                <td>
                  {" "}
                  Limited to necessary user and playlist information. We also
                  manage artist profile activities required by curators before
                  song submissions, such as saving songs, following playlists,
                  and following users or artists.
                </td>
                <td>No public sharing; used for playlist management</td>
              </tr>
            </table> */}
          </Box>
          {/* <Box>
            <Typography className="terms-sub-heading" sx={{ marginTop: "0px" }}>
              3. Analytics Insights
            </Typography>
            <Typography className="terms-about-text">
              By utilizing these cookies, we have the ability to track visits
              and traffic origins, enabling us to gauge and enhance our
              website's efficiency. This insightful data informs us about the
              most and least visited pages, as well as how visitors navigate the
              site. The information collected through these cookies is
              amalgamated, ensuring individual anonymity. If you opt not to
              enable these cookies, we won't be able to detect your site visits,
              impeding our ability to monitor and enhance its performance.
            </Typography>

            <PrivacySectionTableTwo /> */}

          {/* <table>
              <tr>
                <th>Plugin/Service Name</th>
                <th>Purpose</th>
                <th>Data Collection</th>
                <th>Data Sharing</th>
              </tr>
              <tr>
                <td>Google Analytics</td>
                <td>Analytics</td>
                <td>
                  Collects data for website traffic analysis and personalized
                  ads
                </td>
                <td>Limited, respecting privacy (e.g., IP anonymization)</td>
              </tr>
              <tr>
                <td>MetaAds</td>
                <td>Advertising platform for promotions</td>
                <td>Limited to user engagement data for ad targeting</td>
                <td>No public sharing; used for promotion</td>
              </tr>
              <tr>
                <td>Referral Code Plugin</td>
                <td>Facilitates sharing of referral codes</td>
                <td>Limited to referral code information for tracking</td>
                <td>No public sharing; used for referral program</td>
              </tr>
            </table> */}
          {/* </Box> */}
          <Box>
            <Typography className="terms-sub-heading">Contact Point</Typography>

            <Typography className="terms-about-text">
              Feel free to reach out to us if you:<br></br>
              <ul>
                <li>
                  Seek clarification regarding this policy or any other privacy
                  notices on this website.
                </li>
                <li>
                  Wish to gain visibility into the personal data we hold about
                  you.
                </li>
                <li>
                  Have concerns regarding Our handling of your personal data.
                </li>
              </ul>
              <Typography className="terms-about-text">
                Please connect with us through our privacy mailbox at{" "}
                <Link
                  to="mailto:info@pitchplaylists.com"
                  className="text-color-primary"
                >
                  info@pitchplaylists.com
                </Link>{" "}
              </Typography>
            </Typography>
          </Box>
          <Box></Box>
        </Box>
      </Box>
    </>
  );
};
